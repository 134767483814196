/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationState } from '../../store/RootReducers';
import AgencyBackground from '../../component/agencyBackground/AgencyBackground';
import ProgressBar from '../../component/progressBar/ProgressBar';
import { useStyles } from './ApplicationStyles';
import { CanSubmit, LABELS } from './ApplicationConstants';
import { canSubmitApplication, getAllQueryParams, validateAgainstConfig } from './ApplicationUtils';
import ManagedProfiles from '../../component/managedProfiles/ManagedProfiles';
import localStorage from '../../services/LocalStorage';
import { DashboardActions } from '../../store/actions/DashboardActions';
import { SubmittedApplicationResponse } from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import { TypeHelper } from '../../helper/TypeHelper';
import { prefillForms } from '../../helper/PrefillFormHelper';
import { routes } from '../../Routes';
import { GroupsActions } from '../../store/actions/GroupsActions';
import {
  ApplicationsResponse,
  GroupNameRequest,
  GroupResponse,
} from '../../services/groups/Groups';
import { AppToApplyTransitionRequest } from '../../services/dashboard/applyTransition/applyTransition';
import { UpdatingType } from '../../services/dashboard/updating/Updating';
import BackArrow from '../../component/backArrow/BackArrow';
import Text from '../../component/text/Text';
import { PropertyDetailsResponse } from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import PropertyCard from '../../component/propertyCard/PropertyCard';
import BlogCard from '../../component/blogCard/BlogCard';
import GroupCard from '../../component/groupCard/GroupCard';
import Button from '../../component/button/Button';
import Sidebar from '../../component/sidebar/Sidebar';
import Box from '../../component/box/Box';
import add from '../../assets/plus.png';
import { addNewManagedProfile } from '../../component/managedProfiles/ManagedProfilesUtils';
import { LandingScreenState } from '../../store/state/LandingScreenState';
import { ApplicantProfile, PostFormData } from '../../store/state/MyProfileFormState';
import { AdditionalPersonalDetailsScreenActions } from '../../store/actions/AdditionalPersonalDetailsAction';
import { DependentProfile } from '../../services/additionalPersonalDetailsScreen/postFormDetails/PostAdditionalPersonalDetailsForm.data';
import BackgroundCheckBanner from '../../component/backgroundCheckBanner/BackgroundCheckBanner';
import { canShowNTDBanner } from '../../helper/NTDHelper';
import NTDBanner from '../../component/ntdBanner/NTDBanner';
import CreateGroup from './components/createGroup/CreateGroup';
import { useGetBranchConfig } from '../../services/config/branchConfig';

interface ApplicationProps extends RouteComponentProps<{}> {
  masterProfileData: SubmittedApplicationResponse;
  masterProfileFailure: string | null;
  isPrefillHelperCalled: boolean;
  selectedGroupRefId: string | null;
  isAcceptingInvitation: boolean;
  isUpdating: UpdatingType;
  groups: GroupResponse[] | undefined;
  groupApplications: ApplicationsResponse | undefined;
  selectedProperties: PropertyDetailsResponse[] | undefined;
  tenantDetails: LandingScreenState;
  myProfileDetails: ApplicantProfile;
  secondaryApplicantProfiles: ApplicantProfile[];
  dependentProfiles: DependentProfile[];
  prefillData: (data: SubmittedApplicationResponse) => void;
  setPrefillHelperCalled: (isCalled: boolean) => void;
  setGroupRefId: (groupRefId: string | null) => void;
  appToApplyTransition: (data: AppToApplyTransitionRequest) => void;
  updateGroupName: (data: GroupNameRequest) => void;
  addNewProfile: (data: PostFormData) => void;
}

const Application: FC<ApplicationProps> = ({
  history,
  location,
  masterProfileData,
  masterProfileFailure,
  isPrefillHelperCalled,
  selectedGroupRefId,
  isAcceptingInvitation,
  isUpdating,
  groups,
  groupApplications,
  selectedProperties,
  tenantDetails,
  myProfileDetails,
  secondaryApplicantProfiles,
  dependentProfiles,
  prefillData,
  setPrefillHelperCalled,
  setGroupRefId,
  appToApplyTransition,
  updateGroupName,
  addNewProfile,
}) => {
  const classes = useStyles();
  const [openGroupSidebar, setOpenGroupsSidebar] = useState<boolean>(false);
  const selectedGroup = useMemo<GroupResponse | null>(() => {
    if (!groups) return null;
    if (selectedGroupRefId) return groups.find((group) => group.groupRefId === selectedGroupRefId)!;
    return groups[0];
  }, [selectedGroupRefId, groups]);
  const [sidebarSelectedGroup, setSidebarSelectedGroup] = useState<GroupResponse | null>(
    selectedGroup,
  );

  const { branchConfig: agencyConfig } = useGetBranchConfig(selectedProperties![0].agencyCode);

  const { valid: canSubmit } = useMemo<CanSubmit>(() => {
    return canSubmitApplication(myProfileDetails, secondaryApplicantProfiles, dependentProfiles);
  }, [myProfileDetails, secondaryApplicantProfiles, dependentProfiles]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const { agency, groupRefId, responseType, draftIds, applicationRefIds, updatingOne } =
      getAllQueryParams(location);
    // Sorted App transition into Apply
    if (agency && responseType && ((applicationRefIds && groupRefId) || draftIds)) {
      const coreData = {
        agency,
        responseType,
        history,
        updatingOne,
      };
      if (responseType === 'DRAFT') {
        coreData['draftIds'] = draftIds!.split(',');
      } else {
        coreData['groupRefId'] = groupRefId;
        coreData['applicationRefIds'] = applicationRefIds!.split(',');
      }
      appToApplyTransition(coreData);
    }
  }, [location]);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (
      !!userId &&
      !isPrefillHelperCalled &&
      !TypeHelper.isNullOrUndefined(masterProfileData) &&
      !masterProfileFailure
    ) {
      prefillData(masterProfileData);
      setPrefillHelperCalled(true);
    }
  }, [isPrefillHelperCalled, masterProfileData, masterProfileFailure]);

  useEffect(() => {
    if (agencyConfig?.customProfileConfig)
      localStorage.setItem('validationConfig', JSON.stringify(agencyConfig));
  }, [agencyConfig]);

  const hasConfigValidationErrors = useMemo(() => {
    let err = false;

    if (agencyConfig?.customProfileConfig) {
      [myProfileDetails, ...secondaryApplicantProfiles].forEach((p) => {
        if (!validateAgainstConfig(agencyConfig, p).isValid) {
          err = true;
        }
      });
    }
    return err;
  }, [agencyConfig]);

  return (
    <>
      <AgencyBackground />
      <div className={classes.content}>
        <div className={classes.contentContainer}>
          <ProgressBar activeStep={1} />
        </div>
        <div className={classes.pageContainer}>
          {!isAcceptingInvitation && <BackArrow smallGap />}
          <Text textVariant="title" size="xxl" parentStyles={classes.submitTitle}>
            {LABELS.APPLICATION_TITLE}
          </Text>
          <Text textVariant="info" size="l" parentStyles={classes.submitSubtitle}>
            {LABELS.APPLICATION_SUBTITLE}
          </Text>

          <div className={classes.pageContent}>
            <div className={classes.primaryContainer}>
              <div className={classes.contentTitleContainer}>
                <Text textVariant="boxTitle">
                  {LABELS.PROPERTIES(!!selectedProperties && selectedProperties.length === 1)}
                </Text>
              </div>
              {selectedProperties &&
                selectedProperties.map((property, index) => (
                  <PropertyCard property={property} key={index} />
                ))}
            </div>
          </div>
          <div className={classes.pageContent}>
            <div className={classes.primaryContainer}>
              <div className={classes.contentTitleContainer}>
                <Text textVariant="boxTitle">{LABELS.MANAGED_TITLE}</Text>
                <Button
                  parentStyles={classes.titleButton}
                  onPress={() =>
                    addNewManagedProfile(tenantDetails, (result: PostFormData, key: number) => {
                      addNewProfile(result);
                      history.push(routes.additionalApplicant.new(key));
                    })
                  }
                >
                  {LABELS.ADD_MANAGED}
                </Button>
              </div>

              <ManagedProfiles
                agencyConfig={
                  localStorage.getItem('validationConfig')
                    ? JSON.parse(localStorage.getItem('validationConfig')!)
                    : undefined
                }
              />

              {!canShowNTDBanner(myProfileDetails) && <BackgroundCheckBanner />}
            </div>
            <div className={classes.secondaryContainer}>
              <div className={classes.contentTitleContainer}>
                <Text textVariant="boxTitle">{LABELS.FROM_THE_BLOG}</Text>
              </div>
              <BlogCard title={LABELS.BLOG_TITLE} body={LABELS.BLOG_BODY} />
            </div>
          </div>

          <div className={classes.pageContent}>
            <div className={classes.primaryContainer}>
              <div className={classes.contentTitleContainer}>
                <Text textVariant="boxTitle">{LABELS.WHOS_COMING}</Text>
                <Button
                  parentStyles={classes.titleButton}
                  disabled={isUpdating !== false || isAcceptingInvitation !== false}
                  onPress={() => setOpenGroupsSidebar(true)}
                >
                  {selectedGroup ? LABELS.SWITCH_GROUP : LABELS.CREATE_NEW_GROUP}
                </Button>
              </div>
              {selectedGroup ? (
                <GroupCard
                  group={selectedGroup}
                  groupApplications={groupApplications}
                  onNameChange={(groupName: string) =>
                    updateGroupName({ groupName, groupRefId: selectedGroup.groupRefId })
                  }
                  showBottomActions
                  onUpdate={() => {
                    setGroupRefId(selectedGroup.groupRefId);
                    history.push(routes.groups.application);
                  }}
                />
              ) : (
                <CreateGroup />
              )}
            </div>
            <div className={classes.secondaryContainer}>
              <div className={classes.contentTitleContainer}>
                <Text textVariant="boxTitle">{LABELS.FOR_YOU}</Text>
              </div>
              <BlogCard title={LABELS.BLOG_GROUP_TITLE} body={LABELS.BLOG_GROUP_BODY} />
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              disabled={!canSubmit || !selectedGroup || hasConfigValidationErrors}
              parentStyles={classes.submitButton}
              onPress={() => {
                if (canSubmit && !!selectedGroup) {
                  setGroupRefId(selectedGroup.groupRefId);
                  history.push(
                    agencyConfig?.customProfileConfig?.additionalDocuments
                      ? routes.additionalDocuments.view
                      : routes.submitProperties.new,
                  );
                }
              }}
            >
              {LABELS.NEXT}
            </Button>
          </div>
        </div>
      </div>
      <Sidebar
        open={openGroupSidebar}
        title={LABELS.GROUP_SIDEBAR_TITLE}
        subtitle={LABELS.GROUP_SIDE_SUBTITLE}
        onClose={() => setOpenGroupsSidebar(false)}
        secondaryButtonText={LABELS.CANCEL}
        primaryButtonText={LABELS.CONFIRM}
        onPrimary={() => {
          setGroupRefId(sidebarSelectedGroup!.groupRefId);
          setOpenGroupsSidebar(false);
        }}
        onSecondary={() => setOpenGroupsSidebar(false)}
      >
        {groups && sidebarSelectedGroup && (
          <>
            <div className={classes.contentTitleContainer}>
              <Text textVariant="boxTitle">{LABELS.YOUR_GROUPS}</Text>
            </div>
            {groups.map((group, idx) => (
              <div className={classes.sidebarGroupContainer}>
                <GroupCard
                  key={idx}
                  selected={group.groupRefId === sidebarSelectedGroup.groupRefId}
                  group={group}
                  groupApplications={groupApplications}
                  disableNameChange
                  onNameChange={() => null}
                  onPress={() => setSidebarSelectedGroup(group)}
                />
              </div>
            ))}
          </>
        )}
        <Box
          lightBorder
          onPress={() => {
            setGroupRefId(null);
            history.push(routes.groups.application);
          }}
        >
          <div className={classes.addGroupContainer}>
            <Text textVariant="title" size="xl">
              {selectedGroup ? LABELS.ADD_A_NEW_GROUP : LABELS.CREATE_NEW_GROUP}
            </Text>
            <img src={add} className={classes.addGroupIcon} alt="add" />
          </div>
        </Box>
      </Sidebar>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  tenantDetails: state.landingScreen,
  selectedProperties: state.landingScreen.properties!,
  groups: state.groups.groups,
  selectedGroupRefId: state.groups.selectedGroupRefId,
  masterProfileData: state.masterProfile.masterProfileData!,
  masterProfileFailure: state.masterProfile.masterProfileFailure,
  isPrefillHelperCalled: state.dashboard.isPrefillHelperCalled,
  isUpdating: state.dashboard.isUpdating,
  isAcceptingInvitation: state.dashboard.isAcceptingInvitation,
  groupApplications: state.groups.groupApplications,
  myProfileDetails: state.myProfileForm,
  secondaryApplicantProfiles: state.additionalPersonalDetailScreen.secondaryApplicantProfiles,
  dependentProfiles: state.additionalPersonalDetailScreen.dependentProfiles,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateGroupName: (data: GroupNameRequest) => {
    dispatch(GroupsActions.updateGroupNameRequest(data));
  },
  setGroupRefId: (groupRefId: string | null) => {
    dispatch(GroupsActions.setSelectedGroupRef(groupRefId));
  },
  setPrefillHelperCalled: (isCalled: boolean) => {
    dispatch(DashboardActions.setPrefillHelperCalled(isCalled));
  },
  prefillData: (data: SubmittedApplicationResponse) => {
    prefillForms(data, dispatch);
  },
  appToApplyTransition: (data: AppToApplyTransitionRequest) => {
    dispatch(DashboardActions.appToApplyTransitionRequest(data));
  },
  addNewProfile: (data: PostFormData) =>
    dispatch(AdditionalPersonalDetailsScreenActions.postMyProfileFormDetails(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Application));
