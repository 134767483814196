import { QUERY_KEYS } from '../reactQuery/keys';
import { useFetchQuery } from '../reactQuery/reactQuery';
import { ServiceType } from '../ServiceType.data';
import TenancyApiService from '../TenancyApiService';
import { BranchConfig } from './branchConfigType';

const service = new TenancyApiService(ServiceType.BranchConfig);

export const useGetBranchConfig = (code: string) => {
  const { data, isLoading } = useFetchQuery<BranchConfig, {}>(QUERY_KEYS.branchConfig(code), () => {
    // return new Promise((res) => {
    //   res({
    //     'customProfileConfig': {
    //       'validationSchema': {
    //         'minPersonalReferences': 2,
    //         'minProofOfIncomeMonths': 3,
    //         'minAddressHistoryMonths': 24,
    //         'minIdentificationPoints': 100,
    //       },
    //       'additionalDocuments': [
    //         {
    //           'title': 'Utility Bill',
    //           'subtitle':
    //             'Upload a copy of your water, gas, or electricity bill showing your name and address',
    //           'type': AdditionalDocumentsType.UTILITY_BILL,
    //           'bypassLabel': 'I do not have a utility bill',
    //           'count': 2,
    //         },
    //         {
    //           'title': 'Visa',
    //           'subtitle': '(applicable to non-australian citizens)',
    //           'type': AdditionalDocumentsType.VISA_DOCUMENT,
    //           'bypassLabel': 'I am an Australian Cizitizen',
    //         },
    //         {
    //           'title': 'Student enrolment or school offer',
    //           'subtitle': '(only applicable to students)',
    //           'type': AdditionalDocumentsType.ENROLLMENT_DOC,
    //           'bypassLabel': 'I am not a student',
    //         },
    //       ],
    //       'agencyName': 'Jellis-Craig',
    //     },
    //   });
    // });

    return service.get(
      { route: [code], query: { configNameList: 'customProfileConfig' } },
      true,
    ) as any;
  });

  return { branchConfig: data, loadingBranchConfig: isLoading };
};
