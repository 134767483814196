// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import * as serviceWorker from './ServiceWorker';
import { store } from './store/Store';
import theme from './theme/Theme';
import './theme/app.scss';
import SuccessToast from './component/SuccessToast/SuccessToast';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './services/reactQuery/reactQuery';

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <App />
            <SuccessToast />
          </StyledEngineProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </HashRouter>
  </Provider>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
