import React, { FC } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

interface ListBoxProps extends BoxProps {
  direction?: 'row' | 'column';
  gap?: string;
}

const ListBox: FC<ListBoxProps> = ({ direction, children, gap, style, ...rest }) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: direction || 'column',
        gap: gap || '1em',
        width: '100%',
        ...style,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default ListBox;
