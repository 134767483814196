/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-lines */
import moment from 'moment';
import { Dispatch } from 'redux';
import { LandingScreenActions } from '../store/actions/LandingScreenAction';
import {
  SubmittedApplicationResponse,
  Identification,
  Applicants,
  AddressHistory,
  EmploymentHistory,
} from '../services/dashboard/postApplyForm/PostApplyForm.data';
import { OverviewFormData } from '../services/landingScreen/OverviewForm.data';
import { CalculationHelper } from './CalculationHelper';
import {
  Employment,
  Pets,
  PostDeclarationFormData,
  PetTypes,
  SourceOfIncome,
  Reference,
  Vehicles,
} from '../store/state/MyProfileFormState';
import { MyProfileAction } from '../store/actions/MyProfileFormActions';
import { checkIfObjectIsEmpty } from './PostHelper';
import { DependentProfile } from '../services/additionalPersonalDetailsScreen/postFormDetails/PostAdditionalPersonalDetailsForm.data';
import { AdditionalPersonalDetailsScreenActions } from '../store/actions/AdditionalPersonalDetailsAction';
import { store } from '../store/Store';
import { PropertyDetailsResponse } from '../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import localStorage from '../services/LocalStorage';
import { TypeHelper } from './TypeHelper';
import { ID_POINTS } from '../container/profile/components/IdentificationForm/IdentificationFormConstants';
import { MasterProfileActions } from '../store/actions/MasterProfileActions';
import { AustraliaState } from '../services/dashboard/australiaStates/australiaStates';
import { extractState } from './AustralianStateHelper';

/* tslint:disable:cyclomatic-complexity */
export function prefillForms(
  data: SubmittedApplicationResponse,
  dispatch: Dispatch,
  isUpdating = false, // TODO: Remove
  propertyList: any = false,
  propertyRefId: string | null = null, // TODO: Remove
) {
  // To avoid duplicates
  dispatch(AdditionalPersonalDetailsScreenActions.deleteAllSeconadaryProfile());
  dispatch(AdditionalPersonalDetailsScreenActions.deleteAllAdditionalProfile());
  dispatch(LandingScreenActions.deleteAllOtherApplicants());
  /** This condition is added to avoid the failure scenario, if we get data = {} data.applicants = [] */
  if (
    !data ||
    Object.entries(data).length === 0 ||
    (data.applicants && data.applicants.length === 0)
  ) {
    return;
  }
  const allApplicants = [...data.applicants];

  /** Here we are assigning draft-refId's from master-data refId's if it's null */
  if (allApplicants[0].applicantType === 'PRIMARY') {
    const masterData = store.getState().masterProfile.masterProfileData;
    allApplicants[0].refId = masterData ? masterData.applicants[0].refId : allApplicants[0].refId;
  }

  /** adding flag isDisabled to all the references based on submissionStatus value */
  allApplicants.forEach((applicant) => {
    const { applicantAddressHistory, applicantEmploymentHistory, applicantReference } = applicant;
    if (!!applicantAddressHistory && applicantAddressHistory.length > 0) {
      applicantAddressHistory.forEach((individualAddress) => {
        if (
          !!individualAddress.submissionStatus &&
          individualAddress.submissionStatus.toLowerCase() === 'submitted'
        ) {
          individualAddress.isDisabled = true;
        } else {
          individualAddress.isDisabled = false;
        }
      });
    }
    if (!!applicantEmploymentHistory && applicantEmploymentHistory.length > 0) {
      applicantEmploymentHistory.forEach((individualEmp) => {
        if (
          !!individualEmp.submissionStatus &&
          individualEmp.submissionStatus.toLowerCase() === 'submitted'
        ) {
          individualEmp.isDisabled = true;
        } else {
          individualEmp.isDisabled = false;
        }
      });
    }
    if (!!applicantReference && applicantReference.length > 0) {
      applicantReference.forEach((individualReference) => {
        if (
          !!individualReference.submissionStatus &&
          individualReference.submissionStatus.toLowerCase() === 'submitted'
        ) {
          individualReference.isDisabled = true;
        } else {
          individualReference.isDisabled = false;
        }
      });
    }
  });

  if (data.otherApplicants) {
    data.otherApplicants.map((obj) => {
      allApplicants.push(obj);
    });
  }
  const overviewFormData: OverviewFormData = {
    email:
      data.applicants.length && data.applicants[0].email
        ? data.applicants[0].email
        : localStorage.getItem('email')!,
    title: data.applicants.length && data.applicants[0].title ? data.applicants[0].title : '',
    firstName:
      data.applicants.length && data.applicants[0].firstName
        ? data.applicants[0].firstName
        : localStorage.getItem('firstname')!,
    lastName:
      data.applicants.length && data.applicants[0].lastName
        ? data.applicants[0].lastName
        : localStorage.getItem('lastname')!,
    leaseDurationMonth: data.months ? data.months : undefined,
    leaseDurationYear: data.years ? data.years : undefined,
    noOfPeople: getOtherApplicantOverview(allApplicants).length || 1,
    noOfPets: data.noOfPets,
    noOfVehicles: data.noOfVehicles,
    livingWithOthers: getOtherApplicantOverview(allApplicants).length > 0,
    otherApplicants: getOtherApplicantOverview(allApplicants),
    selectedDate: CalculationHelper.isPastDate(data.preferredMoveInDate)
      ? null
      : data.preferredMoveInDate
      ? moment(data.preferredMoveInDate, 'DD/MM/YY').toDate()
      : null,
  };
  dispatch(LandingScreenActions.postFormDetails(overviewFormData));

  if (propertyList && propertyList.length) {
    const propertyData: PropertyDetailsResponse[] = [];
    propertyList.forEach((property: any) => {
      propertyData.push({
        address: property.address,
        addressComponents: property.addressComponents,
        refId: property.propertyRefId,
        applicationRefId: property.applicationRefId || null,
        type: '',
        bedrooms: property.bedrooms || 0,
        bathrooms: property.bathrooms || 0,
        carspaces: property.carspaces || 0,
        description: property.description || '',
        photos: property.photos || [],
        streetNumber: property.streetNumber || '',
        unitNumber: property.unitNumber || '',
        streetName: property.streetName || '',
        suburb: property.suburb || '',
        postcode: property.postcode || '',
        state: property.state || '',
        country: property.country || '',
        agency: property.agency,
        rentalOffer: property.rentOffer,
        inspectionDate: property.inspectionDate ? getDate(property.inspectionDate) : null,
        inspectionMethod: property.inspectionMethod,
        preferredMoveInDate: property.preferredMoveInDate
          ? getDate(property.preferredMoveInDate)
          : null,
        years: property.years,
        months: property.months,
        propertyStatus: property.propertyStatus,
        agencyCode: property.agencyCode || property.agency.agencyCode || '',
      });
    });
    dispatch(MasterProfileActions.setAustraliaState(extractState(propertyData[0].address)));
    dispatch(LandingScreenActions.postPropertyDetails(propertyData));
  }

  /**
   * Prefill applicants data
   */
  if (data && data.applicants.length > 0) {
    /*
     * Primary Applicant Profile
     */
    data.applicants
      .filter((applicantData) => !checkIfObjectIsEmpty(applicantData))
      .map((applicantData, index) => {
        if (applicantData.applicantType !== 'PRIMARY') {
          return;
        }
        // save refId
        dispatch(
          MyProfileAction.saveRefIdForProfile(applicantData.refId ? applicantData.refId : null),
        );

        // Personal Details
        dispatch(MyProfileAction.postFormDetails(personalDetailsFormData(data, index)));

        // intro details
        dispatch(MyProfileAction.postIntroductionDetails(introductionFormData(data, index)));

        // Current Incomes
        dispatch(
          MyProfileAction.postCurrentIncomeFormDetails({
            incomes: currentIncomesFormData(data, index),
          }),
        );

        // Employment Data
        const employmentData: Employment[] = employmentFormData(data, index);

        dispatch(
          MyProfileAction.postEmploymentFormDetails({
            hasBeenEmployed: applicantData.hasBeenEmployed,
            employments: employmentData,
          }),
        );

        // Addresses Data
        dispatch(
          MyProfileAction.postAddressFormDetails({
            addresses: addressFormData(data, index),
          }),
        );

        // Preferences Data
        dispatch(
          MyProfileAction.postReferencesFormDetails({
            references: refrenceFormData(data, index),
            addReference: applicantData.addReference,
          }),
        );

        // Indentification Details
        dispatch(
          MyProfileAction.postIdentificationFormDetails({
            identification: identificationFormData(data, index),
            IDPoints: calculatePoints(identificationFormData(data, index)),
            ntdPurchased:
              data && data.applicants.length > index ? data.applicants[index].ntdPurchased : null,
            ntdActive:
              data && data.applicants.length > index ? data.applicants[index].ntdActive : null,
            ntdDate: data && data.applicants.length > index ? data.applicants[index].ntdDate : null,
          }),
        );

        // Pets Data
        const petData: Pets[] = petsFormData(data, index);

        dispatch(
          MyProfileAction.postPetsFormDetails({
            hasPets: applicantData.hasPets,
            pets: petData,
          }),
        );

        const vehicleData: Vehicles[] = vehicleFormData(data, index);

        dispatch(
          MyProfileAction.postVehiclesFormDetails({
            hasVehicles: applicantData.hasVehicles,
            vehicles: vehicleData,
          }),
        );

        // Declaration
        const declarationData: PostDeclarationFormData = {
          isSortedTermsAccepted: applicantData.isSortedTermsAccepted,
          digitalSignature: applicantData.digitalSignature,
        };
        dispatch(MyProfileAction.postDeclarationFormDetails(declarationData));
      });

    /*
     * Additional Profiles
     */
    data.applicants
      .filter((applicantData) => !checkIfObjectIsEmpty(applicantData))
      .map((applicantData, index) => {
        if (applicantData.applicantType !== 'ADDITIONAL') {
          return;
        }

        // PersonalDetails data
        dispatch(
          AdditionalPersonalDetailsScreenActions.postMyProfileFormDetails(
            profileFormDetails(data, index),
            applicantData.key,
          ),
        );

        // introduction data
        const introText = introductionFormData(data, index);
        dispatch(
          AdditionalPersonalDetailsScreenActions.postIntroductionDetails(
            introText,
            applicantData.key,
          ),
        );

        // Current Incomes
        const currentIncomesData = currentIncomesFormData(data, index);
        dispatch(
          AdditionalPersonalDetailsScreenActions.postCurrentIncomeFormDetails(
            { incomes: currentIncomesData },
            applicantData.key,
          ),
        );

        // Employment Data
        const employmentData: Employment[] = employmentFormData(data, index);
        dispatch(
          AdditionalPersonalDetailsScreenActions.postEmploymentFormDetails(
            {
              hasBeenEmployed: applicantData.hasBeenEmployed,
              employments: employmentData,
            },
            applicantData.key,
          ),
        );

        // Addresses Data
        dispatch(
          AdditionalPersonalDetailsScreenActions.postAddressFormDetails(
            {
              addresses: addressFormData(data, index),
            },
            applicantData.key,
          ),
        );

        // Preferences Data
        dispatch(
          AdditionalPersonalDetailsScreenActions.postReferencesFormDetails(
            {
              references: refrenceFormData(data, index),
              addReference: applicantData.addReference,
            },
            applicantData.key,
          ),
        );

        // Indentification Details
        dispatch(
          AdditionalPersonalDetailsScreenActions.postIdentificationFormDetails(
            {
              identification: identificationFormData(data, index),
              IDPoints: calculatePoints(identificationFormData(data, index)),
              ntdPurchased:
                data && data.applicants.length > index ? data.applicants[index].ntdPurchased : null,
              ntdActive:
                data && data.applicants.length > index ? data.applicants[index].ntdActive : null,
              ntdDate:
                data && data.applicants.length > index ? data.applicants[index].ntdDate : null,
            },
            applicantData.key,
          ),
        );

        // Pets Data
        const petData: Pets[] = petsFormData(data, index);
        dispatch(
          AdditionalPersonalDetailsScreenActions.postPetsFormDetails(
            {
              hasPets: applicantData.hasPets,
              pets: petData,
            },
            applicantData.key,
          ),
        );

        const vehicleData: Vehicles[] = vehicleFormData(data, index);
        dispatch(
          AdditionalPersonalDetailsScreenActions.postVehiclesFormDetails(
            {
              hasVehicles: applicantData.hasVehicles,
              vehicles: vehicleData,
            },
            applicantData.key,
          ),
        );

        // Declaration
        const declarationData: PostDeclarationFormData = {
          isSortedTermsAccepted: applicantData.isSortedTermsAccepted,
          digitalSignature: applicantData.digitalSignature,
        };
        dispatch(
          AdditionalPersonalDetailsScreenActions.postDeclarationFormDetails(
            declarationData,
            applicantData.key,
          ),
        );
        // save refId
        !!applicantData.refId &&
          dispatch(
            AdditionalPersonalDetailsScreenActions.saveRefIdForAdditionalProfile(
              applicantData.refId,
              index,
            ),
          );
      });

    /*
     * Dependent Profiles
     */
    data.applicants
      .filter((applicantData) => !checkIfObjectIsEmpty(applicantData))
      .map((applicantData) => {
        if (applicantData.applicantType !== 'DEPENDENT') {
          return;
        }
        const { firstName, middleName, lastName, dob, title, key, refId } = applicantData;
        const dependentProfileData: DependentProfile = {
          refId,
          key,
          title: !TypeHelper.isNullOrUndefined(title) ? title : '',
          firstName,
          middleName,
          lastName,
          selectedDate: dob ? getDate(dob) : null,
          selectedDateOfBirth: dob ? getDate(dob) : null,
          isChild: true,
          isManaged: true,
        };
        dispatch(AdditionalPersonalDetailsScreenActions.postFormDetails(dependentProfileData, key));
      });
  }
}
/** additional presonal-details parser */
export function profileFormDetails(data: SubmittedApplicationResponse, index: number) {
  const {
    key,
    title,
    firstName,
    email,
    lastName,
    middleName,
    mobile,
    guarantorName,
    guarantorMobile,
    guarantorEmail,
    isManaged,
    dob,
    questions,
    introduction,
    applicantEmergencyContact,
  } = data.applicants[index];
  return {
    key,
    title: !TypeHelper.isNullOrUndefined(title) ? title : '',
    firstName,
    email,
    lastName,
    middleName,
    mobile,
    selectedDateOfBirth: dob ? getDate(dob) : null,
    hasGuarantor: !!guarantorName,
    guarantorName,
    guarantorContactNumber: guarantorMobile,
    guarantorEmail,
    isManaged,
    questions,
    introText: introduction,
    emergencyContactName:
      !!applicantEmergencyContact &&
      !!applicantEmergencyContact[0] &&
      applicantEmergencyContact[0].name
        ? applicantEmergencyContact[0].name
        : '',
    emergencyContactEmail:
      !!applicantEmergencyContact &&
      !!applicantEmergencyContact[0] &&
      applicantEmergencyContact[0].email
        ? applicantEmergencyContact[0].email
        : '',
    emergencyContactMobile:
      !!applicantEmergencyContact &&
      !!applicantEmergencyContact[0] &&
      applicantEmergencyContact[0].mobile
        ? applicantEmergencyContact[0].mobile
        : '',
    emergencyContactRelationship:
      !!applicantEmergencyContact &&
      !!applicantEmergencyContact[0] &&
      applicantEmergencyContact[0].relationship
        ? applicantEmergencyContact[0].relationship
        : null,
  };
}

/** primary personal-details parser */
export function personalDetailsFormData(data: SubmittedApplicationResponse, index: number) {
  const {
    title,
    firstName,
    middleName,
    email,
    lastName,
    mobile,
    guarantorName,
    guarantorMobile,
    guarantorEmail,
    dob,
    questions,
    introduction,
    applicantEmergencyContact,
  } = data.applicants[index];

  return {
    firstName,
    email,
    title: !TypeHelper.isNullOrUndefined(title) ? title : '',
    middleName,
    lastName,
    mobile,
    selectedDateOfBirth: dob ? getDate(dob) : null,
    hasGuarantor: !!guarantorName,
    guarantorName,
    guarantorContactNumber: guarantorMobile,
    guarantorEmail,
    isManaged: true,
    questions,
    introText: introduction,
    emergencyContactName:
      !!applicantEmergencyContact &&
      !!applicantEmergencyContact[0] &&
      applicantEmergencyContact[0].name
        ? applicantEmergencyContact[0].name
        : '',
    emergencyContactEmail:
      !!applicantEmergencyContact &&
      !!applicantEmergencyContact[0] &&
      applicantEmergencyContact[0].email
        ? applicantEmergencyContact[0].email
        : '',
    emergencyContactMobile:
      !!applicantEmergencyContact &&
      !!applicantEmergencyContact[0] &&
      applicantEmergencyContact[0].mobile
        ? applicantEmergencyContact[0].mobile
        : '',
    emergencyContactRelationship:
      !!applicantEmergencyContact &&
      !!applicantEmergencyContact[0] &&
      applicantEmergencyContact[0].relationship
        ? applicantEmergencyContact[0].relationship
        : null,
  };
}

export function introductionFormData(data: SubmittedApplicationResponse, index: number) {
  const { introduction } = data.applicants[index];
  return {
    introText: introduction!,
  };
}

export function currentIncomesFormData(data: SubmittedApplicationResponse, index: number) {
  if (data && data.applicants.length > index && data.applicants[index].applicantIncome) {
    const incomeData = data.applicants[index].applicantIncome;
    if (incomeData !== null) {
      return incomeData
        .filter((income) => !checkIfObjectIsEmpty(income))
        .map((income) => {
          const {
            guarantorMobile,
            guarantorEmail,
            guarantorName,
            source,
            weeklyAmount,
            savingsAmount,
            payslipLink,
            benefitProofLink,
            incomeProofLink,
            bankStatementLink,
            crn,
          } = income;
          return {
            source,
            netWeeklyAmount: weeklyAmount ? weeklyAmount.toString() : null,
            savingsAmount: savingsAmount ? savingsAmount.toString() : null,
            // make payslip null if SourceOfIncome is other than the below options
            payslipData:
              source === SourceOfIncome.I_AM_WORKING_FULL_TIME ||
              source === SourceOfIncome.I_AM_WORKING_PART_TIME ||
              source === SourceOfIncome.I_HAVE_CASUAL_WORK
                ? payslipLink
                : [],
            proofOfBenefitData: benefitProofLink,
            proofOfIncomeData: incomeProofLink,
            bankStatementData: bankStatementLink,
            centrePayReference: crn,
            makeGuarantor: !!guarantorName,
            guarantorName,
            guarantorContactNumber: guarantorMobile,
            guarantorEmail,
          };
        });
    }
  }
  return [];
}

export function employmentFormData(data: SubmittedApplicationResponse, index: number) {
  if (data && data.applicants.length > index && data.applicants[index].applicantEmploymentHistory) {
    const employmentData = data.applicants[index].applicantEmploymentHistory;
    if (employmentData !== null) {
      return employmentData
        .filter((employment) => !checkIfObjectIsEmpty(employment))
        .sort((a, b) => {
          if (a.rank === null && b.rank === null) return 0;
          if (a.rank === null) return 1;
          if (b.rank === null) return -1;
          if (a.rank > b.rank) return 1;
          if (a.rank < b.rank) return -1;
          return 0;
        })
        .map((employment) => {
          const {
            companyName,
            position,
            managerName,
            managerPhone,
            coverLetterLink,
            stillInJob,
            annualSalary,
            startDate,
            endDate,
            employerEmail,
            applyReferenceId,
            isDisabled,
            submissionStatus,
            mailSent,
            address,
            addressComponents,
            rank,
          } = employment;
          return {
            jobStartMonthAndYear: startDate ? getDate(startDate) : null,
            jobEndMonthAndYear: endDate ? getDate(endDate) : null,
            inJob: stillInJob,
            companyName,
            position,
            grossAnnualSalary: annualSalary ? annualSalary.toString() : null,
            managerName,
            email: employerEmail,
            managerContactNumber: managerPhone,
            copyOfEmploymentLetter: coverLetterLink,
            employmentLetterFileName: coverLetterLink ? 'Attachment' : null,
            applyReferenceId,
            isDisabled,
            submissionStatus,
            mailSent: mailSent || false,
            address,
            addressComponents,
            rank,
          };
        });
    }
  }
  return [];
}

export const convertAddressToAddressFormData = (address: AddressHistory) => {
  return {
    livingArrangement: address.currentLivingArrangement,
    address: address.address,
    addressComponents: address.addressComponents,
    agencyName: address.agencyName,
    propertyManagerName: address.propertyManagerName,
    contactNumber: address.mobile ? address.mobile : '',
    monthlyRent: address.monthlyRent ? address.monthlyRent.toString() : null,
    durationOfStayYear: address.years,
    durationOfStayMonth: address.months,
    reasonForLeaving: address.reasonForLeaving,
    postalAddressDifferentFromCurrentAddress: !!address.postalAddress,
    postalAddress: address.postalAddress,
    isCurrent: address.current,
    email: address.propertyManagerEmail,
    applyReferenceId: address.applyReferenceId,
    isDisabled: address.isDisabled,
    submissionStatus: address.submissionStatus,
    mailSent: address.mailSent ? address.mailSent : false,
    additionalAddressDocuments: address.additionalAddressDocuments,
    rank: address.rank,
  };
};

export const convertEmploymentToEmploymentFormData = (employment: EmploymentHistory) => {
  const {
    companyName,
    position,
    managerName,
    managerPhone,
    coverLetterLink,
    stillInJob,
    annualSalary,
    startDate,
    endDate,
    employerEmail,
    applyReferenceId,
    isDisabled,
    submissionStatus,
    mailSent,
    address,
    addressComponents,
    rank,
  } = employment;
  return {
    jobStartMonthAndYear: startDate ? getDate(startDate) : null,
    jobEndMonthAndYear: endDate ? getDate(endDate) : null,
    inJob: stillInJob,
    companyName,
    position,
    grossAnnualSalary: annualSalary ? annualSalary.toString() : null,
    managerName,
    email: employerEmail,
    managerContactNumber: managerPhone,
    copyOfEmploymentLetter: coverLetterLink,
    employmentLetterFileName: coverLetterLink ? 'Attachment' : null,
    applyReferenceId,
    isDisabled,
    submissionStatus,
    mailSent: mailSent || false,
    address,
    addressComponents,
    rank,
  };
};

export const convertReferenceToReferenceFormData = (reference: Reference) => {
  const {
    email,
    mobile,
    name,
    relationship,
    applyReferenceId,
    isDisabled,
    submissionStatus,
    mailSent,
    rank,
  } = reference;
  return {
    name,
    relationship: relationship ? relationship.toString() : null,
    email,
    mobile: mobile ? mobile.toString() : null,
    applyReferenceId,
    isDisabled,
    submissionStatus,
    mailSent: mailSent || false,
    rank,
  };
};
export function addressFormData(data: SubmittedApplicationResponse, index: number) {
  if (data && data.applicants.length > index && data.applicants[index].applicantAddressHistory) {
    const addressHistory = data.applicants[index].applicantAddressHistory;
    if (addressHistory !== null) {
      return addressHistory
        .filter((address) => !checkIfObjectIsEmpty(address))
        .sort((a, b) => {
          if (a.rank === null && b.rank === null) return 0;
          if (a.rank === null) return 1;
          if (b.rank === null) return -1;
          if (a.rank > b.rank) return 1;
          if (a.rank < b.rank) return -1;
          return 0;
        })
        .map((address) => {
          return {
            livingArrangement: address.currentLivingArrangement,
            address: address.address,
            addressComponents: address.addressComponents,
            agencyName: address.agencyName,
            propertyManagerName: address.propertyManagerName,
            contactNumber: address.mobile ? address.mobile : '',
            monthlyRent: address.monthlyRent ? address.monthlyRent.toString() : null,
            durationOfStayYear: address.years,
            durationOfStayMonth: address.months,
            reasonForLeaving: address.reasonForLeaving,
            postalAddressDifferentFromCurrentAddress: !!address.postalAddress,
            postalAddress: address.postalAddress,
            isCurrent: address.current,
            email: address.propertyManagerEmail,
            applyReferenceId: address.applyReferenceId,
            isDisabled: address.isDisabled,
            submissionStatus: address.submissionStatus,
            mailSent: address.mailSent ? address.mailSent : false,
            additionalAddressDocuments: address.additionalAddressDocuments,
            rank: address.rank,
          };
        });
    }
  }
  return [];
}

export function refrenceFormData(data: SubmittedApplicationResponse, index: number) {
  if (data && data.applicants.length > index && data.applicants[index].applicantReference) {
    const { applicantReference } = data.applicants[index];
    if (applicantReference !== null) {
      return applicantReference
        .filter((reference) => !checkIfObjectIsEmpty(reference))
        .sort((a, b) => {
          if (a.rank === null && b.rank === null) return 0;
          if (a.rank === null) return 1;
          if (b.rank === null) return -1;

          if (a.rank > b.rank) return 1;
          if (a.rank < b.rank) return -1;
          return 0;
        })
        .map((reference) => {
          const {
            email,
            mobile,
            name,
            relationship,
            applyReferenceId,
            isDisabled,
            submissionStatus,
            mailSent,
            rank,
          } = reference;
          return {
            name,
            relationship: relationship ? relationship.toString() : null,
            email,
            mobile: mobile ? mobile.toString() : null,
            applyReferenceId,
            isDisabled,
            submissionStatus,
            mailSent: mailSent || false,
            rank,
          };
        });
    }
  }
  return [];
}

export function identificationFormData(data: SubmittedApplicationResponse, index: number) {
  if (data && data.applicants.length > index && data.applicants[index].applicantIdentification) {
    const identificationDetails = data.applicants[index].applicantIdentification;
    if (identificationDetails !== null) {
      return identificationDetails
        .filter((id) => !checkIfObjectIsEmpty(id))
        .map((identity) => {
          const { identificationLink, type, idNumber, extendedData } = identity;
          let identification;
          if (type === 'DRIVING_LICENSE') {
            identification = {
              type: type ? type.toString() : null,
              identificationLink,
              fileName: identificationLink ? 'Attachment' : null,
              licenseNumber: idNumber,
              stateOfIssueDL:
                extendedData && extendedData.stateOfIssueDL ? extendedData.stateOfIssueDL : null,
              countryOfOrigin: null,
              passportNumber: null,
              cardNumber: null,
              referenceNumber: null,
              medicareColour: null,
              expiryDate:
                extendedData && extendedData.expiryDate ? getDate(extendedData.expiryDate) : null,
            };
          } else if (type === 'PASSPORT') {
            identification = {
              type: type ? type.toString() : null,
              identificationLink,
              fileName: identificationLink ? 'Attachment' : null,
              stateOfIssueDL: null,
              licenseNumber: null,
              passportNumber: idNumber,
              countryOfOrigin:
                extendedData && extendedData.countryOfOrigin ? extendedData.countryOfOrigin : null,
              cardNumber: null,
              referenceNumber: null,
              medicareColour: null,
              expiryDate:
                extendedData && extendedData.expiryDate ? getDate(extendedData.expiryDate) : null,
            };
          } else if (type === 'MEDICARE') {
            identification = {
              type: type ? type.toString() : null,
              identificationLink,
              fileName: identificationLink ? 'Attachment' : null,
              stateOfIssueDL: null,
              licenseNumber: null,
              countryOfOrigin: null,
              passportNumber: null,
              cardNumber: extendedData && extendedData.cardNumber ? extendedData.cardNumber : null,
              referenceNumber: idNumber,
              medicareColour:
                extendedData && extendedData.medicareColour ? extendedData.medicareColour : null,
              expiryDate:
                extendedData && extendedData.expiryDate ? getDate(extendedData.expiryDate) : null,
            };
          } else {
            identification = {
              type: type ? type.toString() : null,
              identificationLink,
              fileName: identificationLink ? 'Attachment' : null,
              stateOfIssueDL: null,
              licenseNumber: null,
              passportNumber: null,
              countryOfOrigin: null,
              cardNumber: null,
              referenceNumber: null,
              medicareColour: null,
              expiryDate:
                extendedData && extendedData.expiryDate ? getDate(extendedData.expiryDate) : null,
            };
          }
          return identification;
        });
    }
  }
  return [];
}

export function calculatePoints(identificationData: Identification[]) {
  if (identificationData) {
    const points = identificationData
      .map((identification): number => {
        if (identification && identification.type) {
          return ID_POINTS[identification.type];
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0); // adding all elements of array.
    return points;
  }
  return 0;
}

export function vehicleFormData(data: SubmittedApplicationResponse, index: number) {
  if (data && data.applicants.length > index && data.applicants[index].applicantVehicles) {
    const vehicleData = data.applicants[index].applicantVehicles;
    if (vehicleData !== null) {
      return vehicleData.filter((vehicle) => !checkIfObjectIsEmpty(vehicle));
    }
  }
  return [];
}

export function petsFormData(data: SubmittedApplicationResponse, index: number) {
  if (data && data.applicants.length > index && data.applicants[index].applicantPet) {
    const petData = data.applicants[index].applicantPet;
    if (petData !== null) {
      return petData
        .filter((pet) => !checkIfObjectIsEmpty(pet))
        .map((pet) => {
          const isPetTypeOther = pet.type !== PetTypes.Dog && pet.type !== PetTypes.Cat;
          return {
            type: isPetTypeOther ? PetTypes.Other : pet.type,
            specifyOtherType: isPetTypeOther ? pet.type : null,
            breed: pet.breed,
            desexed: pet.desexedStatus,
            size: pet.size,
            additionalDocuments: pet.additionalPetDocuments,
            registrationNumber: pet.registrationNumber,
          };
        });
    }
  }
  return [];
}

export function getDate(dateString: any) {
  let inspectedDate;
  if (dateString) {
    const date = dateString.split('/');
    inspectedDate = new Date(date[2], date[1] - 1, date[0]);
  }
  return inspectedDate;
}

export function getOtherApplicantOverview(data: Applicants[]) {
  const overviewData: any = [];
  const secondaryProfiles = data.filter((a: Applicants) => a.applicantType === 'ADDITIONAL');
  const dependentProfiles = data.filter((a: Applicants) => a.applicantType === 'DEPENDENT');

  secondaryProfiles.forEach((a: Applicants) => {
    overviewData.push({
      key: a.key,
      title: !TypeHelper.isNullOrUndefined(a.title) ? a.title : '',
      firstName: a.firstName,
      middleName: a.middleName,
      lastName: a.lastName,
      email: a.email,
      selectedDate: undefined,
      isChild: false,
      isManaged: a.isManaged,
      mobile: a.mobile ? a.mobile : '',
    });
  });

  dependentProfiles.forEach((a: Applicants) => {
    overviewData.push({
      key: a.key,
      title: !TypeHelper.isNullOrUndefined(a.title) ? a.title : '',
      firstName: a.firstName,
      middleName: a.middleName,
      lastName: a.lastName,
      email: '',
      selectedDate: getDate(a.dob),
      isChild: true,
      isManaged: true,
    });
  });

  return overviewData;
}

export function formatQuestion(question: string) {
  return question.replace(' ', '').toLowerCase();
}
