import React, { FC } from 'react';
import { useStyles } from './ProgressBarStyles';
import Box from '../box/Box';
import { BranchConfig } from '../../services/config/branchConfigType';
import localStorage from '../../services/LocalStorage';

interface ProgressBarProps {
  activeStep: number;
  showLabels?: boolean;
  steps?: string[];
}

const ProgressBar: FC<ProgressBarProps> = ({
  activeStep,
  showLabels = true,
  steps = localStorage.getItem('validationConfig') &&
  (JSON.parse(localStorage.getItem('validationConfig')!) as BranchConfig).customProfileConfig
    .additionalDocuments?.length > 0
    ? ['Properties', 'Application', 'Documents', 'Submit']
    : ['Properties', 'Application', 'Submit'],
}) => {
  const classes = useStyles();
  return (
    <Box lightBorder parentStyles={classes.progressBarContainer} innerStyles={classes.innerBox}>
      <div className={classes.progressBarWrapper}>
        {steps.map((label, index) => (
          <>
            {index !== 0 && <div className={classes.line} />}
            <div className={classes.step}>
              <div
                className={`${classes.stepIcon} ${
                  activeStep === index ? classes.activeIcon : undefined
                }`}
              >
                {index + 1}
              </div>
              {showLabels && (
                <div
                  className={`${classes.stepLabel} ${
                    activeStep === index ? classes.activeLabel : undefined
                  }`}
                >
                  {label}
                </div>
              )}
            </div>
          </>
        ))}
      </div>
    </Box>
  );
};

export default ProgressBar;
