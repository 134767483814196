import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import {
  actionGreen,
  maxContentWidth,
  pageContainer,
  primaryContent,
  secondaryContent,
} from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    ...maxContentWidth,
  },
  pageContainer: {
    ...pageContainer,
    paddingBottom: 0,
    paddingTop: 24,
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      paddingLeft: 24,
      paddingRight: 24,
      minWidth: 'calc(100% - 48px)',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingLeft: 12,
      paddingRight: 12,
      minWidth: 'calc(100% - 24px)',
    },
  },
  contentContainer: {
    paddingLeft: '96px',
    paddingRight: '96px',
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  pageContent: {
    marginTop: theme.units.spacing * 12,
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      flexDirection: 'column',
    },
  },
  primaryContainer: {
    ...primaryContent,
    maxWidth: '100%',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      maxWidth: '100%',
    },
  },
  secondaryContainer: {
    ...secondaryContent,
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      marginLeft: 0,
      marginTop: theme.units.spacing * 8,
      maxWidth: '100%',
    },
  },
  submitTitle: {
    marginBottom: theme.units.spacing * 4,
  },
  submitSubtitle: {
    marginBottom: theme.units.spacing * 12,
    maxWidth: 800,
  },
  contentTitleContainer: {
    // marginBottom: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleButton: {
    marginBottom: theme.units.spacing * 4 - 2,
  },
  buttonContainer: {
    margin: 'auto',
    marginTop: theme.units.spacing * 8,
    paddingBottom: '80px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  submitButton: {
    minWidth: 200,
  },
  sidebarGroupContainer: {
    marginBottom: theme.units.spacing * 4,
  },
  addGroupContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.units.spacing * 2,
    paddingBottom: theme.units.spacing * 2,
  },
  addGroupIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    objectFit: 'contain',
    objectPosition: 'center',
    ...actionGreen,
  },
  statusIconAvatar: {
    padding: '0.4em',
    '&.incomplete': {
      backgroundColor: theme.colors.lightOrange,
    },
    '&.complete': {
      backgroundColor: theme.colors.lightGreen,
    },
  },
  sectionHeading: {
    marginBottom: 0,
  },
  uploadButton: {
    height: '30px',
    padding: '0px 24px',
  },
  completeIcon: {
    color: theme.colors.secondary,
  },
  incompleteIcon: {
    color: theme.colors.darkOrange,
  },
  fileBox: {
    border: `2px solid ${theme.colors.borderColorLight}`,
    borderRadius: 8,
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    '&.deleting': {
      opacity: 0.5,
      mouseEvents: 'none',
    },
  },
});
