import React, { FC, useEffect, useState, useMemo, CSSProperties, ReactNode } from 'react';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { useStyles } from './ButtonStyles';

interface ButtonProps {
  children: string | ReactNode;
  outline?: boolean;
  parentStyles?: string;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
  loading?: boolean;
  muted?: boolean;
  onPress: () => void;
}

const Button: FC<ButtonProps> = ({
  children,
  outline,
  type,
  parentStyles,
  disabled,
  loading,
  muted,
  onPress,
}) => {
  const classes = useStyles();
  return (
    <button
      className={classNameGenerator([
        classes.buttonContainer,
        muted && classes.muted,
        outline && classes.outline,
        disabled && classes.disabled,
        parentStyles,
      ])}
      type={type}
      onClick={() => (disabled || type === 'submit' ? null : onPress())}
    >
      <div
        className={classNameGenerator([
          loading && (outline ? classes.loadingOutline : classes.loading),
        ])}
      />
      <div className={classNameGenerator([loading && classes.hide])}>{children}</div>
    </button>
  );
};

export default Button;
