import { makeStyles } from '@mui/styles';
import { color, theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import { actionGreen, maxContentWidth, pageContainer } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    ...maxContentWidth,
  },
  pageContainer: {
    ...pageContainer,
    marginTop: 0,
    paddingTop: 0,
  },
  snackbarStyles: {
    marginBottom: 20,
  },
  snackbarContent: {
    display: 'flex',
    alignItems: 'center',
  },
  snackbarIcons: {
    height: 25,
    width: 25,
  },
  advancedSearchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: -theme.units.spacing * 6,
    marginBottom: theme.units.spacing * 6,
  },
  advancedSearchItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    objectPosition: 'center',
    objectFit: 'contain',
    ...actionGreen,
  },
  advancedSearchInfoText: {
    marginLeft: theme.units.spacing * 4,
  },
  advancedSearchText: {
    marginRight: theme.units.spacing * 4,
  },
  loader: {
    color: color.primary,
  },
});
