import * as Yup from 'yup';
import {
  EMAIL_PATTERN,
  CONTACT_NUMBER_PATTERN,
  NAME_PATTERN,
  invalidContactNumber,
  NUMBER_PATTERN,
} from '../../../../constants/AppConstants';
import { SourceOfIncome } from '../../../../store/state/MyProfileFormState';

export const LABELS = {
  CURRENT_INCOME: 'Current Income',
  INCOME: 'Income',
  SOURCE_OF_INCOME: 'Source of income',
  PART_TIME_EMPLOYED: 'I am part time employed',
  NET_WEEKLY_AMOUNT: 'Net Weekly Amount',
  GUARANTOR_QUESTION: 'Do you want to make them as your guarantor?',
  GUARANTOR_NAME: 'Guarantor name',
  CONTACT_NUMBER: 'Contact number',
  EMAIL_ADDRESS: 'Email address',
  ADD_ANOTHER_INCOME: '+ Add another income',
  RECOMMENDATION:
    'Having savings or benefits you may be receiving on top of your income can improve your chances of success as it increases the property affordability',
  BUTTON_TEXT: 'Save and next',
  LATER: "I'LL DO IT LATER",
  REMOVE: 'Remove',
  FILE_SIZE_ERROR: 'Upload failed. File size cannot exceed 10 MB.',
  TOTAL_AMOUNT: 'Total amount',
  ATTACH_PAYSLIP: 'Attach payslip (PDF, JPG, PNG) 10MB *',
  ATTACH_PAYSLIP_DESCRIPTION: (minConfig?: number) =>
    `Please add at least ${minConfig || 3} latest payslips`,
  ATTACH_BANK_STATEMENT: 'Attach bank statement (PDF, JPG, PNG) 10MB',
  PROOF_OF_SUPPORT: 'Please provide proof of savings (PDF, JPG, PNG) 10MB',
  ATTACH_BANK_STATEMENT_DESCRIPTION: (minConfig?: number) =>
    `Please upload proof of bank balance statement including your name and address that shows at least ${
      minConfig || 3
    } months of income`,
  ATTACH_PROOF_OF_BENEFIT: 'Attach proof of benefit *',
  ATTACH_PROOF_OF_INCOME: 'Attach proof of income *',
  CENTRE_PAY_REFERENCE: 'Centrelink reference number',
  ATTACH_TAX_RETURN: 'Please attach proof of income (PDF, JPG, PNG) 10MB',
  ATTACH_TAX_RETURN_DESCRIPTION:
    'Please add atleast three previous months proof of income e.g. tax return statements.',
};

export const ERRORS = {
  required: 'Required',
  invalidNameFormat: 'Invalid name format',
  invalidEmail: 'Invalid email',
  minWeeklyAmount: 'Cannot be less than 1',
  maxStringLength: 'Can be at most 255 characters',
  payslipRequired: 'Please add payslip',
  bankStatementRequired: 'Please add bank statement',
  proofOfBenefitRequired: 'Please add proof of benefit',
  centrePayReferenceRequired: 'Please provide centrelink reference number',
  proofOfIncomeRequired: 'Please add proof of income',
  sourceRequired: 'Please add source of income',
  netWeeklyAmount: 'Please provide amount',
  invalidCountryNumber: 'Invalid phone number',
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  incomes: Yup.array().of(
    Yup.object().shape({
      source: Yup.string().nullable(),
      makeGuarantor: Yup.boolean(),
      netWeeklyAmount: Yup.string()
        .when('source', {
          is: SourceOfIncome.I_AM_WORKING_FULL_TIME,
          then: Yup.string().matches(NUMBER_PATTERN, ERRORS.minWeeklyAmount),
        })
        .when('source', {
          is: SourceOfIncome.I_AM_WORKING_PART_TIME,
          then: Yup.string().matches(NUMBER_PATTERN, ERRORS.minWeeklyAmount),
        })
        .when('source', {
          is: SourceOfIncome.I_HAVE_CASUAL_WORK,
          then: Yup.string().matches(NUMBER_PATTERN, ERRORS.minWeeklyAmount),
        })
        .when('source', {
          is: SourceOfIncome.I_RECEIVE_A_BENEFIT,
          then: Yup.string().matches(NUMBER_PATTERN, ERRORS.minWeeklyAmount),
        })
        .when('source', {
          is: SourceOfIncome.I_RECEIVE_MONEY_FROM_MY_FAMILY,
          then: Yup.string().matches(NUMBER_PATTERN, ERRORS.minWeeklyAmount),
        })
        .when('source', {
          is: SourceOfIncome.OTHER,
          then: Yup.string().matches(NUMBER_PATTERN, ERRORS.minWeeklyAmount),
        })
        .nullable(),
      savingsAmount: Yup.string()
        .when('source', {
          is: SourceOfIncome.I_RECEIVE_SAVINGS,
          then: Yup.string().matches(NUMBER_PATTERN, ERRORS.minWeeklyAmount),
        })
        .nullable(),
      guarantorName: Yup.string()
        .when('makeGuarantor', {
          is: true,
          then: Yup.string()
            .max(255, ERRORS.maxStringLength)
            .matches(NAME_PATTERN, ERRORS.invalidNameFormat)
            .nullable(),
        })
        .when('makeGuarantor', {
          is: false,
          then: Yup.string().nullable(),
        }),
      guarantorContactNumber: Yup.string()
        .when('makeGuarantor', {
          is: true,
          then: Yup.string().nullable(),
        })
        .when('makeGuarantor', {
          is: false,
          then: Yup.string().nullable(),
        }),
      guarantorEmail: Yup.string()
        .when('makeGuarantor', {
          is: true,
          then: Yup.string().nullable(),
        })
        .when('makeGuarantor', {
          is: false,
          then: Yup.string().nullable(),
        }),
      centrePayReference: Yup.string()
        .when('source', {
          is: SourceOfIncome.I_RECEIVE_A_BENEFIT,
          then: Yup.string().nullable(),
        })
        .nullable(),
    }),
  ),
});
