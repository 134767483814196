import { outlinedInputClasses } from '@mui/material';
import { ThemeOptions, createTheme } from '@mui/material/styles';
import { getFonts } from '../helper/GetFonts';
import { LocalStorageKeys } from '../services/LocalStorage';
import SORTED from './themes/SORTED';

export const layerIndex = {
  labelAboveInput: 0x100,
  iconAboveForm: 0x200,
  dialogAboveForm: 0x300,
  autoCompletePopUp: 0x400,
};
const themeCollection: ThemeType[] = [...SORTED];
const defaultTheme = themeCollection[0];
export enum ThemeKey {
  SORTED = 'SORTED',
}

export const getThemeKey = (): ThemeKey | undefined => {
  const result = localStorage.getItem(LocalStorageKeys.APP_THEME);
  if (result) return result as ThemeKey;
  return undefined;
};

export const setThemeKey = (themeKey: ThemeKey) =>
  localStorage.setItem(LocalStorageKeys.APP_THEME, themeKey);

const getTheme = (themeKey?: ThemeKey): ThemeType => {
  return {
    ...(themeCollection.find((theme) => theme.key === themeKey) || defaultTheme),
  };
};

export const theme = getTheme(getThemeKey());

interface ThemeType {
  key: string;
  colors: {
    light: string;
    lightFaded: string;
    lightGrey: string;
    black87: string;
    grey: string;
    darkGrey: string;
    secondary: string;
    black38: string;
    black: string;
    label: string;
    borderColor: string;
    borderColorLight: string;
    disabled: string;
    filterBackground: string;
    textarea: string;
    fadedBlue: string;
    fadedPurple: string;
    disabledText: string;
    buttonPrimary: string;
    black60: string;
    offerOverlay: string;
    inputBackground: string;
    inputText: string;
    title: string;
    subtitle: string;
    error: string;
    info: string;
    success: string;
    warning: string;
    focus: string;
    averageUsage: string;
    budgetUsage: string;
    sortedPurple: string;
    completeLabel: string;
    incompleteLabel: string;
    yellowLabel: string;
    lightBlue: string;
    darkBlue: string;
    lightGreen: string;
    darkGreen: string;
    lightPurple: string;
    darkPurple: string;
    lightOrange: string;
    darkOrange: string;
    lightRed: string;
    lightRedError: string;
    darkRed: string;
    errorPink: string;
  };
  font: {
    family: string;
    family2: string;
    xxs: number;
    xs: number;
    s: number;
    m: number;
    l: number;
    xl: number;
    xxl: number;
    xxxl: number;
    buttonCase: string;
  };
  units: {
    spacing: number;
    borderRadius: number;
    iconSize: number;
  };
}

export const color = {
  primary: '#1f0d33',
  secondary: '#fafafa',
  secondary100: 'rgba(0, 0, 0, 0.12)',
  secondary200: '#202020',
  secondary300: '#a9a9a9',
  secondary400: '#232323',
};

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: ['GT Walsheim', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h4: {
      fontSize: 15,
      fontWeight: 300,
      lineHeight: 1.15,
    },
    h5: {
      fontSize: 27.2,
      fontWeight: 600,
      lineHeight: 'normal',
      color: color.primary,
    },
    h6: {
      fontSize: 23.3,
      fontWeight: 300,
      color: theme.colors.grey,
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 1.5,
      color: theme.colors.black60,
    },
    body1: {
      fontSize: 19,
      lineHeight: 1.5,
      fontWeight: 300,
      color: theme.colors.grey,
    },
    body2: {
      fontSize: 16.3,
      lineHeight: 1.23,
      color: theme.colors.grey,
    },
    caption: {
      fontSize: 13.6,
      fontWeight: 600,
      lineHeight: 1.18,
      letterSpacing: 0.4,
      color: color.primary,
    },
    button: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.14,
      letterSpacing: 0.8,
      color: theme.colors.grey,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: color.primary,
          color: theme.colors.light,
          textAlign: 'center',
        },
        containedSecondary: {
          backgroundColor: color.primary,
          color: theme.colors.light,
          textAlign: 'center',
        },
        disabled: {
          backgroundColor: theme.colors.grey,
          color: theme.colors.black,
          textAlign: 'center',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          '&:after': {
            color: color.primary,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: `2px solid ${theme.colors.borderColor}`,
          },
          '&:after': {
            borderBottom: `2px solid #1f0d33`,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          fontWeight: 500,
          marginTop: 8,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: theme.font.l,
          fontWeight: getFonts('Medium'),
          color: theme.colors.subtitle,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontFamily: 'GT Walsheim',
          fontWeight: 500,
          color: '#000000',
        },
      },
    },
  },
};

const appTheme = createTheme(themeOptions);

export default appTheme;
