import React, { FC, useMemo, useRef, useState } from 'react';
import AgencyBackground from '../../component/agencyBackground/AgencyBackground';
import ProgressBar from '../../component/progressBar/ProgressBar';
import BackArrow from '../../component/backArrow/BackArrow';
import { LABELS } from './AdditionalDocumentsConstants';
import { useStyles } from './AdditionalDocumentsStyles';
import Text from '../../component/text/Text';
import Box from '../../component/box/Box';
import { useGetBranchConfig } from '../../services/config/branchConfig';
import ListBox from '../../component/listBox/ListBox';
import {
  CheckOutlined,
  DeleteOutlineOutlined,
  DescriptionOutlined,
  InfoOutlined,
} from '@mui/icons-material';
import { Avatar, Checkbox, Box as MuiBox } from '@mui/material';
import Button from '../../component/button/Button';
import { theme } from '../../theme/Theme';
import { AdditionalDocumentsType } from '../../services/config/branchConfigType';
import {
  useDeleteDocument,
  useGetAllDocs,
  useSubmitAdditionalDocs,
  useUploadDocument,
} from '../../services/additionalVerification/additionalVerificationService';
import { groupBy } from 'lodash';
import localStorage from '../../services/LocalStorage';
import { RouteComponentProps, withRouter } from 'react-router';
import { routes } from '../../Routes';

interface AdditionalDocumentsProps extends RouteComponentProps {}

const AdditionalDocuments: FC<AdditionalDocumentsProps> = ({ history }) => {
  const classes = useStyles();
  const { branchConfig, loadingBranchConfig } = useGetBranchConfig('AU_JCCO');
  const [bypass, setBypass] = useState<AdditionalDocumentsType[]>([]);
  const [uploadingType, setUploadingType] = useState<AdditionalDocumentsType | null>(null);
  const [uploadError, setUploadError] = useState<Error | null>(null);
  const [deletingDocId, setDeletingDocId] = useState<string | null>(null);
  const [deletedDocIds, setDeletedDocIds] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const email = localStorage.getItem('email')!;

  // api calls
  const { uploadFile, uploadingFile } = useUploadDocument();
  const { uploadedDocs, refetchDocs } = useGetAllDocs({ email });
  const { deleteDoc } = useDeleteDocument();
  const { submitDocs, submittingDocs } = useSubmitAdditionalDocs();

  const categorisedUploadedDocs = useMemo(() => {
    if (uploadedDocs) {
      return groupBy(uploadedDocs, (el) => el.type);
    }
    return undefined;
  }, [uploadedDocs]);

  const docs = useMemo(() => branchConfig?.customProfileConfig.additionalDocuments, [branchConfig]);

  const uploadedTypes = categorisedUploadedDocs ? Object.keys(categorisedUploadedDocs) : [];

  const disabled =
    !docs ||
    !categorisedUploadedDocs ||
    docs.some(
      ({ type, count }) =>
        !bypass.includes(type) && categorisedUploadedDocs[type]?.length !== (count || 1),
    ) ||
    submittingDocs;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      uploadFile(
        { email, type: uploadingType!, files: Array.from(event.target.files) },
        {
          onSuccess: (_data, variables) => {
            refetchDocs()
              .then(() => {
                setBypass((bp) => bp.filter((b) => b !== variables.type));
              })
              .catch(() => {})
              .finally(() => {
                inputRef.current!.value = '';
              });
          },
          onError: (err) => {
            setUploadError(err);
          },
          onSettled: () => {
            setUploadingType(null);
          },
        },
      );
    }
  };

  return loadingBranchConfig ? null : (
    <>
      <AgencyBackground />
      <div className={classes.content}>
        <div className={classes.contentContainer}>
          <ProgressBar
            steps={['Properties', 'Application', 'Documents', 'Submit']}
            activeStep={2}
          />
        </div>
        <div className={classes.pageContainer}>
          <BackArrow smallGap />
        </div>
      </div>

      <div className={classes.contentContainer}>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.contentTitleContainer}>
              <Text textVariant="boxTitle">{LABELS.heading}</Text>
            </div>
          </div>
        </div>

        <ListBox>
          {docs!.map(({ title, subtitle, bypassLabel, type, count }) => {
            const complete =
              bypass.includes(type) || categorisedUploadedDocs?.[type]?.length === (count || 1);
            const uploadingToThisCategory = uploadingFile && uploadingType === type;
            return (
              <Box lightBorder key={title}>
                <ListBox direction="row">
                  <Avatar
                    className={`${classes.statusIconAvatar} ${
                      complete ? 'complete' : 'incomplete'
                    }`}
                  >
                    {complete ? (
                      <CheckOutlined className={classes.completeIcon} />
                    ) : (
                      <InfoOutlined className={classes.incompleteIcon} />
                    )}
                  </Avatar>
                  <ListBox gap="0.8em">
                    <Text parentStyles={classes.sectionHeading} textVariant="contentTitle">
                      {title} {count ? `(${count} Required)` : ''}
                    </Text>
                    <Text textVariant="info">{subtitle}</Text>

                    <MuiBox style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <ListBox gap="0.2em" style={{ alignItems: 'center' }} direction="row">
                        <Checkbox
                          onChange={(_e, checked) => {
                            if (checked) {
                              setBypass((bp) => [...bp, type]);
                            } else {
                              setBypass((bp) => bp.filter((b) => b !== type));
                            }
                          }}
                          style={{ color: theme.colors.secondary }}
                          disabled={uploadedTypes.includes(type)}
                        />
                        <Text textVariant="info">{bypassLabel}</Text>
                      </ListBox>
                      <Button
                        parentStyles={classes.uploadButton}
                        onPress={() => {
                          if (inputRef.current) {
                            inputRef.current.click();
                          }
                          setUploadingType(type);
                          setUploadError(null);
                        }}
                        disabled={uploadingToThisCategory}
                      >
                        {uploadingToThisCategory ? LABELS.uploading : LABELS.upload}
                      </Button>
                    </MuiBox>

                    {uploadError && uploadingType === type && <MuiBox mt={2}>{uploadError}</MuiBox>}

                    {/* Uploaded files list */}
                    {categorisedUploadedDocs?.[type] ? (
                      <ListBox>
                        {categorisedUploadedDocs?.[type].map((f) => {
                          if (deletedDocIds.includes(f.id)) return null;
                          const beingDeleted = deletingDocId === f.id;
                          return (
                            <MuiBox
                              className={`${classes.fileBox} ${beingDeleted ? 'deleting' : ''}`}
                            >
                              <ListBox direction="row">
                                <DescriptionOutlined /> {f.fileData.fileName}
                              </ListBox>
                              <DeleteOutlineOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (!beingDeleted) {
                                    setDeletingDocId(f.id);
                                    deleteDoc(
                                      { documentId: f.id },
                                      {
                                        onSuccess: () => {
                                          setDeletedDocIds((ddi) => [...ddi, f.id]);
                                          setDeletingDocId(null);
                                        },
                                        onError: () => {
                                          setDeletingDocId(null);
                                        },
                                      },
                                    );
                                  }
                                }}
                              />
                            </MuiBox>
                          );
                        })}
                      </ListBox>
                    ) : null}
                  </ListBox>
                </ListBox>
              </Box>
            );
          })}
        </ListBox>

        <MuiBox my={2} display="flex" justifyContent="flex-end">
          <Button
            disabled={disabled}
            onPress={() => {
              submitDocs(
                {
                  documentIds:
                    uploadedDocs?.map((ud) => ud.id).filter((i) => !deletedDocIds.includes(i)) ||
                    [],
                  email,
                  draftApplicationId: parseInt(localStorage.getItem('draftId')!),
                },
                {
                  onSuccess: () => {
                    history.push(routes.submitProperties.new);
                  },
                },
              );
            }}
          >
            {submittingDocs ? LABELS.submitting : LABELS.submit}
          </Button>
        </MuiBox>

        {/* Hidden file input */}
        <input
          type="file"
          ref={inputRef}
          style={{ display: 'none' }}
          accept="image/*,.pdf"
          onChange={handleFileChange}
        />
      </div>
    </>
  );
};

export default withRouter(AdditionalDocuments);
