/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useState, useEffect } from 'react';
import { Snackbar } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/RootReducers';
import AgencyBackground from '../../component/agencyBackground/AgencyBackground';
import { useStyles } from './SelectPropertiesStyles';
import {
  MatchParams,
  getAllQueryParams,
  registerClient,
  isValidPropertyURL,
} from '../../helper/URLHelper';
import SearchProperties from './components/SearchProperties/SearchProperties';
import Properties from './components/Properties/Properties';
import {
  AgencyDetails,
  PropertyDetailsResponse,
} from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import { DashboardActions, DashboardActionTypes } from '../../store/actions/DashboardActions';
import { LoginActions } from '../../store/actions/LoginActions';
import localStorage from '../../services/LocalStorage';
import { RegisterClientResponse } from '../../services/login/LoginData';
import addIcon from '../../assets/success.svg';
import removeIcon from '../../assets/remove.svg';
import { SubmittedApplicationResponse } from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import { prefillForms } from '../../helper/PrefillFormHelper';
import { UpdatingType } from '../../services/dashboard/updating/Updating';
import { errorMessageSelector } from '../../store/selectors/ErrorSelector';
import { MyProfileAction } from '../../store/actions/MyProfileFormActions';

interface SelectPropertiesProps extends RouteComponentProps<MatchParams> {
  properties: PropertyDetailsResponse[] | undefined;
  agencyDetails: AgencyDetails | undefined;
  registerClientDetails: RegisterClientResponse | undefined;
  error: string;
  branch: string | null | undefined;
  masterProfileData: SubmittedApplicationResponse;
  isPrefillHelperCalled: boolean;
  isUpdating: UpdatingType;
  clearError: () => void;
  postRegisterClient: () => void;
  clearPropertyDetails: () => void;
  prefillData: (
    data: SubmittedApplicationResponse,
    isUpdating: boolean,
    propertyList: any,
    propertyRefId: string | null,
  ) => void;
  setPrefillHelperCalled: (isCalled: boolean) => void;
  refreshMasterProfile: () => void;
  setMyAccountFlag: (value: boolean) => void;
}

const SelectProperties: FC<SelectPropertiesProps> = ({
  history,
  location,
  match,
  registerClientDetails,
  error,
  masterProfileData,
  isPrefillHelperCalled,
  isUpdating,
  postRegisterClient,
  clearPropertyDetails,
  prefillData,
  setPrefillHelperCalled,
  refreshMasterProfile,
  setMyAccountFlag,
  properties,
}) => {
  const classes = useStyles();
  const [URLError, setURLError] = useState<boolean>(false);
  const [snackBarText, setSnackBarText] = useState<string>('');
  const [snackBarState, setSnackBarState] = useState<boolean>(false);
  const [failedSearchString, setFailedSearchString] = useState<string | undefined>('');
  const [selectedProperties, setSelectedProperties] = useState<PropertyDetailsResponse[]>(
    properties || [],
  );

  useEffect(() => {
    const { refId, type, agentId, address, suburb, state, postcode, streetNumber, streetName } =
      getAllQueryParams(location, match.params);

    if (
      !isValidPropertyURL(
        refId,
        type,
        agentId,
        address,
        suburb,
        state,
        postcode,
        streetNumber,
        streetName,
      )
    ) {
      setURLError(true);
    }

    const clientId = localStorage.getItem('clientId');
    if (!clientId || clientId === 'undefined') {
      postRegisterClient();
    } else {
      const clientSecret = localStorage.getItem('clientSecret');
      const basicAuthHash = window.btoa(`${clientId}:${clientSecret}`);
      localStorage.setItem('authTokenBasic', basicAuthHash);
    }
  }, []);

  useEffect(() => {
    registerClient(registerClientDetails);
    setMyAccountFlag(false);
  }, [registerClientDetails]);

  useEffect(() => {
    const { agentId, suburb, state, postcode, streetNumber, streetName, unitNumber } =
      getAllQueryParams(location, match.params);

    if (error && agentId && (streetNumber || streetName || suburb || postcode)) {
      const searchAddress = `${
        unitNumber ? `${unitNumber}/` : ''
      }${streetNumber} ${streetName}, ${suburb} ${state} ${postcode}`;
      setFailedSearchString(searchAddress);
    }
  }, [error]);

  useEffect(() => {
    if (!isPrefillHelperCalled && masterProfileData) {
      prefillData(masterProfileData, isUpdating !== false, false, null);
      setPrefillHelperCalled(true);
    } else if (!masterProfileData) {
      refreshMasterProfile();
    }
  }, [masterProfileData, isPrefillHelperCalled, isUpdating]);

  return (
    <>
      <AgencyBackground />
      <div className={classes.content}>
        <div className={classes.pageContainer}>
          <>
            <SearchProperties
              location={location}
              failedSearchString={failedSearchString}
              selectedProperties={selectedProperties}
              setSelectedProperties={setSelectedProperties}
              setSnackBarState={(value: boolean) => setSnackBarState(value)}
              setSnackBarText={(value: string) => setSnackBarText(value)}
              disabled={isUpdating !== false}
              agencyDisabled={isUpdating !== false || !!selectedProperties.length}
            />

            <Properties
              history={history}
              location={location}
              selectedProperties={selectedProperties}
              deleteProperty={(i) => {
                clearPropertyDetails();
                setSelectedProperties((old) => {
                  const newProperties = JSON.parse(
                    JSON.stringify(old),
                  ) as PropertyDetailsResponse[];
                  return newProperties.filter((_, index) => index !== i);
                });
              }}
              readonly={isUpdating !== false}
              setSnackBarState={(value: boolean) => setSnackBarState(value)}
              setSnackBarText={(value: string) => setSnackBarText(value)}
            />
          </>
        </div>
      </div>

      <Snackbar
        className={classes.snackbarStyles}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={() => setSnackBarState(false)}
        open={snackBarState}
        autoHideDuration={2000}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <span id="message-id" className={classes.snackbarContent}>
            <img
              className={classes.snackbarIcons}
              src={snackBarText === 'Property added' ? addIcon : removeIcon}
              alt=""
            />
            &nbsp; &nbsp;
            {snackBarText}
          </span>
        }
      />
    </>
  );
};

const error = errorMessageSelector([
  DashboardActionTypes.FETCH_PROPERTY_DETAILS,
  DashboardActionTypes.FETCH_PROPERTIES,
  DashboardActionTypes.GET_PROPERTY_BY_ADDRESS,
  DashboardActionTypes.GET_PROPERTY_BY_REAXML,
  DashboardActionTypes.GET_PROPERTY_BY_REAXML_SEARCH,
]);

const mapStateToProps = (state: ApplicationState) => ({
  registerClientDetails: state.login.registerClientDetails,
  agencyDetails: state.dashboard.agencyDetails,
  properties: state.landingScreen.properties!,
  error: error(state),
  branch: state.dashboard.branch,
  masterProfileData: state.masterProfile.masterProfileData!,
  isPrefillHelperCalled: state.dashboard.isPrefillHelperCalled,
  isUpdating: state.dashboard.isUpdating,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setMyAccountFlag: (data: boolean) => {
    dispatch(MyProfileAction.setMyAccountFlag(data));
  },
  clearError: () => {
    dispatch(DashboardActions.clearApplyErrror());
  },
  postRegisterClient: () => {
    dispatch(LoginActions.postRegisterClientStart());
  },
  clearPropertyDetails: () => {
    dispatch(DashboardActions.clearPropertyDetails());
  },
  prefillData: (
    data: SubmittedApplicationResponse,
    isUpdating: boolean,
    propertyList: any,
    propertyRefId: string | null = null,
  ) => {
    prefillForms(data, dispatch, isUpdating, propertyList, propertyRefId);
  },
  setPrefillHelperCalled: (isCalled: boolean) => {
    dispatch(DashboardActions.setPrefillHelperCalled(isCalled));
  },
  refreshMasterProfile: () => {
    dispatch(LoginActions.refreshMasterProfile());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectProperties);
