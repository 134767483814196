import React, { FC, useEffect, useState, useMemo, ReactNode, CSSProperties } from 'react';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { useStyles } from './BoxStyles';
import { ErrorOutline } from '@mui/icons-material';
import { Typography } from '@mui/material';

interface BoxProps {
  children?: ReactNode;
  lightBorder?: boolean;
  parentStyles?: string;
  innerStyles?: string;
  loading?: boolean;
  selected?: boolean;
  onPress?: () => void;
  error?: string;
}

const Box: FC<BoxProps> = ({
  children,
  parentStyles,
  lightBorder,
  innerStyles,
  loading,
  selected,
  onPress,
  error,
}) => {
  const classes = useStyles();
  const className = [
    parentStyles,
    classes.boxContainer,
    lightBorder && classes.lightBorder,
    selected && classes.selected,
    onPress !== undefined && classes.clickable,
    loading && classes.loading,
  ];
  if (error) className.push('error');
  return (
    <div className={classNameGenerator(className)} onClick={onPress}>
      <div className={classNameGenerator([classes.boxWrapper, innerStyles])}>{children}</div>
      {error && (
        <div className={classes.errorContainer}>
          <ErrorOutline style={{ marginRight: '0.5em' }} />{' '}
          <Typography className={classes.errorMessage}>{error}</Typography>
        </div>
      )}
    </div>
  );
};

export default Box;
