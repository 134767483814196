/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { theme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { loadingAnimation } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  boxContainer: {
    borderRadius: theme.units.borderRadius,
    border: `${theme.colors.borderColor} 2px solid`,
    backgroundColor: theme.colors.light,
    transition: 'all 0.5s ease 0s',

    '&.error': {
      borderColor: theme.colors.errorPink,
      color: 'white',
    },
  },
  lightBorder: {
    border: `${theme.colors.borderColorLight} 2px solid`,
  },
  selected: {
    border: `${theme.colors.secondary} 2px solid`,
  },
  clickable: {
    cursor: 'pointer',
  },
  boxWrapper: {
    padding: theme.units.spacing * 4,
  },
  loading: {
    border: 'none',
    ...loadingAnimation,
  },
  errorContainer: {
    background: theme.colors.errorPink,
    padding: '8px 16px',
    borderRadius: `0px 0px 8px 8px`,
    display: 'flex',
    alignItems: 'center',
  },
  errorMessage: {
    fontSize: 14,
    fontWeight: 500,
    color: 'white',
  },
});
