/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useState, useEffect, useMemo } from 'react';
import { Paper, Typography, Dialog } from '@mui/material';
import { connect } from 'react-redux';
import { History } from 'history';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducers';
import AgencyBackground from '../../component/agencyBackground/AgencyBackground';
import ProgressBar from '../../component/progressBar/ProgressBar';
import { useStyles } from './SubmitPropertiesStyles';
import { LABELS } from './SubmitPropertiesConstants';
import {
  Assets,
  PropertyDetailsResponse,
} from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import propertyDefaultIcon from '../../assets/homePlaceholder.png';
import petsDefaultIcon from '../../assets/outline-pets-24-px-light.svg';
import profileDefaultIcon from '../../assets/profile-default.svg';
import SimilarProperties from '../../component/similarProperties/SimilarProperties';
import DeclarationForm from './components/declarationForm/DeclarationForm';
import { getOccupantsString } from '../../helper/GroupHelper';
import { routes } from '../../Routes';
import { ApplicationsResponse, GroupResponse } from '../../services/groups/Groups';
import Gravatars from '../../component/gravatars/Gravatars';
import {
  CustomTermsHeaderPayload,
  CustomTermsHeaderActions,
  CustomTermsHeaderResponse,
} from '../../store/actions/CustomTermsHeaderActions';
import MandatoryError from '../../component/errorPage/MandatoryError';
import { UpdatingType, UPDATING_TYPE } from '../../services/dashboard/updating/Updating';
import bedrooms from '../../assets/bed.png';
import bathrooms from '../../assets/bath.png';
import carspaces from '../../assets/car.png';
import SORTED_CIRCULAR_LOGO from '../../assets/logosCircle/sorted.png';
import Text from '../../component/text/Text';
import Box from '../../component/box/Box';
import Button from '../../component/button/Button';
import moment from 'moment';
import GroupCard from '../../component/groupCard/GroupCard';
import BackArrow from '../../component/backArrow/BackArrow';
import { ApplicantProfile } from '../../store/state/MyProfileFormState';
import { DependentProfile } from '../../services/additionalPersonalDetailsScreen/postFormDetails/PostAdditionalPersonalDetailsForm.data';
import { CanSubmit } from '../application/ApplicationConstants';
import { canSubmitApplication } from '../application/ApplicationUtils';
import localStorage from '../../services/LocalStorage';
import { BranchConfig } from '../../services/config/branchConfigType';

interface SubmitPropertiesProps {
  history: History;
  selectedProperties: PropertyDetailsResponse[];
  isUpdating: UpdatingType;
  isAcceptingInvitation: boolean;
  onSuccessfulSubmission: boolean;
  groups: GroupResponse[] | undefined;
  selectedGroupRefId: string | null;
  agencyCode: string | null | undefined;
  terms: CustomTermsHeaderResponse;
  assets: Assets | null | undefined;
  groupApplications: ApplicationsResponse | undefined;
  myProfileDetails: ApplicantProfile;
  secondaryApplicantProfiles: ApplicantProfile[];
  dependentProfiles: DependentProfile[];
  getTermsHeader: (data: CustomTermsHeaderPayload) => void;
}

const SubmitProperties: FC<SubmitPropertiesProps> = ({
  history,
  selectedProperties,
  isUpdating,
  isAcceptingInvitation,
  onSuccessfulSubmission,
  groups,
  selectedGroupRefId,
  agencyCode,
  terms,
  assets,
  groupApplications,
  myProfileDetails,
  secondaryApplicantProfiles,
  dependentProfiles,
  getTermsHeader,
}) => {
  const classes = useStyles();
  const [showDeclaration, setShowDeclaration] = useState<boolean>(false);
  const agencyConfig = localStorage.getItem('validationConfig')
    ? (JSON.parse(localStorage.getItem('validationConfig')!) as BranchConfig)
    : undefined;

  const { valid: canSubmit, reason: cannotSubmitReason } = useMemo<CanSubmit>(() => {
    return canSubmitApplication(myProfileDetails, secondaryApplicantProfiles, dependentProfiles);
  }, [myProfileDetails, secondaryApplicantProfiles, dependentProfiles]);

  useEffect(() => {
    if (agencyCode && !terms) {
      getTermsHeader({
        agency: agencyCode.toUpperCase(),
      });
    }
  }, []);

  useEffect(() => {
    if (onSuccessfulSubmission) {
      if (selectedProperties && selectedProperties.length) {
        history.replace({
          pathname: routes.success.new,
          // Todo workout what isDraft means
          state: { state: selectedProperties, isDraft: false },
        });
      } else {
        // Todo Add properties details in state (as above) here too when the flow is unified
        history.replace(routes.success.new);
      }
    }
  }, [onSuccessfulSubmission]);

  const getRefIds = (): string[] => {
    const result: string[] = [];
    selectedProperties.map((property) => {
      result.push(property.refId);
    });
    return result;
  };

  return (
    <>
      <AgencyBackground />
      <div className={classes.content}>
        <div className={classes.contentContainer}>
          <ProgressBar
            activeStep={(agencyConfig?.customProfileConfig?.additionalDocuments?.length && 3) || 2}
          />
        </div>
        <div className={classes.pageContainer}>
          <BackArrow smallGap />
          <Text textVariant="title" size="xxl" parentStyles={classes.submitTitle}>
            {isUpdating !== false
              ? isUpdating === UPDATING_TYPE.UPDATING
                ? LABELS.UPDATE_TITLE
                : LABELS.SYNC_PROFILE
              : LABELS.SUBMIT_TITLE}
          </Text>

          <Text textVariant="info" size="l" parentStyles={classes.submitSubtitle}>
            {isUpdating !== false
              ? LABELS.UPDATE_SUBTITLE(
                  selectedProperties.length === 1,
                  isUpdating === UPDATING_TYPE.SYNCING,
                )
              : LABELS.SUBMIT_SUBTITLE(selectedProperties.length === 1)}
          </Text>
          <div className={classes.pageContent}>
            <div className={classes.primaryContainer}>
              <div className={classes.contentTitleContainer}>
                <Text textVariant="contentTitle">
                  {LABELS.PROPERTIES(selectedProperties.length === 1)}
                </Text>
              </div>
              {selectedProperties.map((property, index) => (
                <Box lightBorder parentStyles={classes.boxContainer} key={index}>
                  <div className={classes.applicationRow}>
                    <div className={classes.propertyContainer}>
                      <img
                        className={classes.property}
                        src={
                          property.photos && !!property.photos.length
                            ? property.photos[0]
                            : propertyDefaultIcon
                        }
                        alt="property"
                      />
                    </div>
                    <div className={classes.applicationDetails}>
                      <div className={classes.applicationDetailsMiddle}>
                        <div className={classes.propertyAddressContainer}>
                          <Text textVariant="title" parentStyles={classes.address1}>
                            {`${property.unitNumber ? `${property.unitNumber}/` : ''}${
                              property.streetNumber || ''
                            } ${property.streetName}`}
                          </Text>
                          <Text textVariant="info" parentStyles={classes.address2}>
                            {`${property.suburb}, ${property.state} ${property.postcode}`}
                          </Text>
                        </div>
                        <div className={classes.rentAndAgencyContainer}>
                          <div className={classes.agencyContainer}>
                            {assets && assets.circularLogo && (
                              <img
                                className={classes.agencyLogo}
                                onError={(e) => {
                                  (e.target as HTMLImageElement).src = SORTED_CIRCULAR_LOGO;
                                }}
                                src={assets?.circularLogo}
                                alt="agency logo"
                              />
                            )}
                          </div>
                          <div className={classes.rentContainer}>
                            <div className={classes.rentRow}>
                              <Text textVariant="title" parentStyles={classes.rent}>
                                {property.rentalOffer ? `$${property.rentalOffer}` : '-'}
                              </Text>
                            </div>

                            <Text textVariant="info" parentStyles={classes.rentInfo}>
                              {LABELS.RENT_PW}
                            </Text>
                          </div>
                        </div>
                      </div>
                      <div className={classes.applicationDetailsFooter}>
                        <div className={classes.statsRow}>
                          {!!property.bedrooms && (
                            <div className={classes.stat}>
                              <img className={classes.statIcon} src={bedrooms} alt="people stat" />
                              <div className={classes.statTextRow}>
                                <Text textVariant="info" parentStyles={classes.statNumber}>
                                  {property.bedrooms}
                                </Text>
                                <Text textVariant="info" parentStyles={classes.statText}>
                                  {LABELS.BEDROOMS}
                                </Text>
                              </div>
                            </div>
                          )}
                          {!!property.bathrooms && (
                            <div className={classes.stat}>
                              <img
                                className={classes.statIcon}
                                src={bathrooms}
                                alt="applications stat"
                              />
                              <div className={classes.statTextRow}>
                                <Text textVariant="info" parentStyles={classes.statNumber}>
                                  {property.bathrooms}
                                </Text>
                                <Text textVariant="info" parentStyles={classes.statText}>
                                  {LABELS.BATHROOMS}
                                </Text>
                              </div>
                            </div>
                          )}
                          {!!property.carspaces && (
                            <div className={classes.stat}>
                              <img
                                className={classes.statIcon}
                                src={carspaces}
                                alt="applications stat"
                              />
                              <div className={classes.statTextRow}>
                                <Text textVariant="info" parentStyles={classes.statNumber}>
                                  {property.carspaces}
                                </Text>
                                <Text textVariant="info" parentStyles={classes.statText}>
                                  {LABELS.CARSPACES}
                                </Text>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.lineRow} />
                  <div className={classes.questionGrid}>
                    <div className={classes.questionContainer}>
                      <Text textVariant="contentTitle" size="l">
                        {LABELS.DID_YOU_INSPECT}
                      </Text>
                      <Text textVariant="info">{property.inspectionMethod}</Text>
                    </div>
                    {!!property.inspectionDate && (
                      <div className={classes.questionContainer}>
                        <Text textVariant="contentTitle" size="l">
                          {LABELS.INSPECTION_DATE}
                        </Text>
                        <Text textVariant="info">
                          {moment(property.inspectionDate, 'DD/MM/YYYY').format('DD/MM/YYYY')}
                        </Text>
                      </div>
                    )}
                    <div className={classes.questionContainer}>
                      <Text textVariant="contentTitle" size="l">
                        {LABELS.PREFERRED_MOVE_IN_DATE}
                      </Text>
                      <Text textVariant="info">
                        {moment(property.preferredMoveInDate, 'DD/MM/YYYY').format('DD/MM/YYYY')}
                      </Text>
                    </div>
                    <div className={classes.questionContainer}>
                      <Text textVariant="contentTitle" size="l">
                        {LABELS.PREFERRED_LEASE_DURATION}
                      </Text>
                      <Text textVariant="info">
                        {property.years ? `${property.years} year(s) ` : ' '}
                        {property.months ? `${property.months} months(s) ` : ' '}
                      </Text>
                    </div>
                  </div>
                </Box>
              ))}
            </div>
            <div className={classes.secondaryContainer}>
              <div className={classes.contentTitleContainer}>
                <Text textVariant="contentTitle">{LABELS.GROUP}</Text>
              </div>
              {groups &&
                groups
                  .filter((group) => group.groupRefId === selectedGroupRefId)
                  .map((group, index) => (
                    <GroupCard
                      key={index}
                      group={group}
                      groupApplications={groupApplications}
                      showBottomActions={false}
                      disableNameChange
                      onNameChange={(groupName: string) => null}
                    />
                  ))}
            </div>
          </div>

          {isAcceptingInvitation === false && isUpdating === false ? (
            <SimilarProperties refIds={getRefIds()} history={history} />
          ) : null}

          {!canSubmit && (
            <div className={classes.errorContainer}>
              <MandatoryError
                errorText={!canSubmit ? cannotSubmitReason : 'Mandatory information is missing'}
              />
            </div>
          )}

          <div className={classes.buttonContainer}>
            <Button
              disabled={!canSubmit}
              parentStyles={classes.submitButton}
              onPress={() => (canSubmit ? setShowDeclaration(true) : null)}
            >
              {isUpdating !== false
                ? isUpdating === UPDATING_TYPE.UPDATING
                  ? LABELS.UPDATE
                  : LABELS.SYNC
                : LABELS.SUBMIT}
            </Button>
          </div>
          <Dialog open={showDeclaration} maxWidth="md">
            <DeclarationForm setShowDeclaration={(show: boolean) => setShowDeclaration(show)} />
          </Dialog>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  selectedProperties: state.landingScreen.properties!,
  isUpdating: state.dashboard.isUpdating,
  isAcceptingInvitation: state.dashboard.isAcceptingInvitation,
  onSuccessfulSubmission: state.groups.applicationSubmitted,
  groups: state.groups.groups,
  selectedGroupRefId: state.groups.selectedGroupRefId,
  agencyCode: state.dashboard.agency,
  terms: state.termsHeader.termsHeaderDetails,
  assets: state.dashboard.assets,
  groupApplications: state.groups.groupApplications,
  myProfileDetails: state.myProfileForm,
  secondaryApplicantProfiles: state.additionalPersonalDetailScreen.secondaryApplicantProfiles,
  dependentProfiles: state.additionalPersonalDetailScreen.dependentProfiles,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getTermsHeader: (data: CustomTermsHeaderPayload) => {
    dispatch(CustomTermsHeaderActions.postCustomTermsHeaderStart(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitProperties);
