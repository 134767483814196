import React, { FC, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useStyles } from './GroupsStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store/RootReducers';
import { LABELS } from './GroupsConstants';
import Input from '../../component/input/Input';
import Text from '../../component/text/Text';
import Box from '../../component/box/Box';
import Button from '../../component/button/Button';
import {
  ApplicationsResponse,
  GroupNameRequest,
  GroupRequest,
  GroupResponse,
} from '../../services/groups/Groups';
import { SubmittedApplicationResponse } from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import { GroupsActions, GroupsActionTypes } from '../../store/actions/GroupsActions';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import GroupCard from '../../component/groupCard/GroupCard';
import ManagedProfiles from '../../component/managedProfiles/ManagedProfiles';
import BlogCard from '../../component/blogCard/BlogCard';
import { LandingScreenState } from '../../store/state/LandingScreenState';
import { ApplicantProfile, PostFormData } from '../../store/state/MyProfileFormState';
import { AdditionalPersonalDetailsScreenActions } from '../../store/actions/AdditionalPersonalDetailsAction';
import { addNewManagedProfile } from '../../component/managedProfiles/ManagedProfilesUtils';
import { routes } from '../../Routes';
import { MyProfileAction } from '../../store/actions/MyProfileFormActions';
import { DependentProfile } from '../../services/additionalPersonalDetailsScreen/postFormDetails/PostAdditionalPersonalDetailsForm.data';

interface GroupsProps extends RouteComponentProps {
  groups: GroupResponse[] | undefined;
  loading: boolean;
  masterProfileData: SubmittedApplicationResponse | null;
  groupApplications: ApplicationsResponse | undefined;
  tenantDetails: LandingScreenState;
  secondaryApplicantProfiles: ApplicantProfile[];
  dependentProfiles: DependentProfile[];
  getGroupMasterProfile: (data: GroupRequest) => void;
  updateGroupName: (data: GroupNameRequest) => void;
  addNewProfile: (data: PostFormData) => void;
  setGroupRefId: (groupRefId: string | null) => void;
  setMyAccountFlag: (value: boolean) => void;
  deleteGroup: (data: GroupRequest) => void;
}

const Groups: FC<GroupsProps> = ({
  groups,
  history,
  loading,
  masterProfileData,
  groupApplications,
  tenantDetails,
  secondaryApplicantProfiles,
  dependentProfiles,
  getGroupMasterProfile,
  updateGroupName,
  addNewProfile,
  setGroupRefId,
  setMyAccountFlag,
  deleteGroup,
}) => {
  const groupSectionRef = useRef<HTMLDivElement>(null);
  const [groupSectionHeight, setGroupSectionHeight] = useState<number>(600);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    getGroupMasterProfile({});
    setMyAccountFlag(true);
  }, [masterProfileData]);

  const classes = useStyles();
  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>{LABELS.TITLE}</div>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.row}>
              <Text textVariant="boxTitle">{LABELS.YOUR_GROUPS}</Text>
              <Button
                parentStyles={classes.addGroup}
                onPress={() => {
                  setGroupRefId(null);
                  history.push(routes.groups.edit);
                }}
              >
                {LABELS.ADD_GROUP}
              </Button>
            </div>
            <div
              className={classes.groupsContainer}
              ref={groupSectionRef}
              style={{
                maxHeight: `${groupSectionHeight}px`,
                opacity: groupSectionHeight > 0 ? 1 : 0,
              }}
            >
              {!groups && loading ? (
                <>
                  {[...Array(4).fill(null)].map((_, i) => (
                    <Box key={i} parentStyles={classes.loadingCard} loading />
                  ))}
                </>
              ) : (
                <>
                  {groups!.map((group, idx) => (
                    <GroupCard
                      key={idx}
                      group={group}
                      showBottomActions
                      groupApplications={groupApplications}
                      onDelete={
                        groups!.length === 1
                          ? undefined
                          : () => deleteGroup({ groupRefId: group.groupRefId })
                      }
                      onUpdate={() => {
                        setGroupRefId(group.groupRefId);
                        history.push(routes.groups.edit);
                      }}
                      onNameChange={(groupName: string) =>
                        updateGroupName({ groupName, groupRefId: group.groupRefId })
                      }
                    />
                  ))}
                </>
              )}
            </div>
            {groups && groups.length > 2 && groupSectionHeight <= 600 && (
              <div className={classes.showAllContainer}>
                <Button
                  outline
                  parentStyles={classes.addGroup}
                  onPress={() => {
                    setGroupSectionHeight(
                      groupSectionHeight > 600 ? 600 : groupSectionRef.current!.scrollHeight,
                    );
                  }}
                >
                  {LABELS.SEE_ALL(groups.length)}
                </Button>
              </div>
            )}
          </div>
          <div className={classes.secondaryContainer}>
            <Text textVariant="boxTitle">{LABELS.FOR_YOU}</Text>
            <BlogCard title={LABELS.BLOG_GROUP_TITLE} body={LABELS.BLOG_GROUP_BODY} />
            <BlogCard title={LABELS.BLOG_GROUP_TITLE2} body={LABELS.BLOG_GROUP_BODY2} />

            {/* <Box parentStyles={classes.notifications} lightBorder>
              <Text textVariant="info">No notifications</Text>
            </Box> */}
          </div>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.row}>
              <Text textVariant="boxTitle">{LABELS.MANAGED_PROFILES}</Text>
              <Button
                parentStyles={classes.addGroup}
                onPress={() =>
                  addNewManagedProfile(tenantDetails, (result: PostFormData, key: number) => {
                    addNewProfile(result);
                    history.push(routes.additionalApplicant.new(key));
                  })
                }
              >
                {LABELS.ADD_MANAGED}
              </Button>
            </div>

            <ManagedProfiles hidePrimary />
            {secondaryApplicantProfiles.length === 0 && dependentProfiles.length === 0 && (
              <Box lightBorder innerStyles={classes.noManagedContainer}>
                <Text textVariant="info" parentStyles={classes.noManaged}>
                  {LABELS.NO_MANAGED}
                </Text>
                <Button
                  parentStyles={classes.createNew}
                  onPress={() =>
                    addNewManagedProfile(tenantDetails, (result: PostFormData, key: number) => {
                      addNewProfile(result);
                      history.push(routes.additionalApplicant.new(key));
                    })
                  }
                >
                  {LABELS.ADD_MANAGED}
                </Button>
              </Box>
            )}
          </div>
          <div className={classes.secondaryContainer}>
            <Text textVariant="boxTitle">{LABELS.FROM_THE_BLOG}</Text>
            <BlogCard title={LABELS.BLOG_TITLE} body={LABELS.BLOG_BODY} />
          </div>
        </div>
      </div>
    </div>
  );
};

const loading = loadingSelector([
  GroupsActionTypes.GET_GROUP_MASTER_PROFILE,
  GroupsActionTypes.DELETE_GROUP,
]);

const mapStateToProps = (state: ApplicationState) => ({
  loading: loading(state),
  tenantDetails: state.landingScreen,
  masterProfileData: state.masterProfile.masterProfileData,
  groups: state.groups.groups,
  groupApplications: state.groups.groupApplications,
  secondaryApplicantProfiles: state.additionalPersonalDetailScreen.secondaryApplicantProfiles,
  dependentProfiles: state.additionalPersonalDetailScreen.dependentProfiles,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setMyAccountFlag: (data: boolean) => {
    dispatch(MyProfileAction.setMyAccountFlag(data));
  },
  updateGroupName: (data: GroupNameRequest) => {
    dispatch(GroupsActions.updateGroupNameRequest(data));
  },
  getGroupMasterProfile: (data: GroupRequest) => {
    dispatch(GroupsActions.getGroupMasterProfileRequest(data));
  },
  addNewProfile: (data: PostFormData) =>
    dispatch(AdditionalPersonalDetailsScreenActions.postMyProfileFormDetails(data)),
  setGroupRefId: (groupRefId: string | null) => {
    dispatch(GroupsActions.setSelectedGroupRef(groupRefId));
  },
  deleteGroup: (data: GroupRequest) => {
    dispatch(GroupsActions.deleteGroupRequest(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Groups));
